import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import {
  Box,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
} from '@mui/material';
import { SearchIcon, CheckboxIcon, Checkbox_checked, Disabled_checkbox } from "../../customform/src/assets";
import { ProfileImage, Card_number_icon, Date_icon, LocationIcon, Security_code_icon, Submit_details_icon, Submitted_details_icon, VisaIcon, MasterCard, ApplePay, AmericanExpressIcon, Maestro, DiscoverIcon, visaIcon, phoneCallIcon, cardErrorIcon } from "./assets"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CustomStepper from "../../customform/src/components/CustomStepper.web";

// Customizable Area End

import Splitpayments2Controller, {
  Props,
  configJSON,
} from "./Splitpayments2Controller";

export default class Splitpayments2 extends Splitpayments2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleHomeAddressSubHead = () => {
    if (this.isHomeAddressComplete()) {
      return (
        <>
          <Typography className="showPolicy">{`${this.state.homeAddress.homeAddress}, ${this.state.homeAddress.StreetAddress2}`}{this.state.homeAddress.StreetAddress2 ? "," : ""}</Typography>
          <Typography className="showPolicy">{`${this.state.homeAddress.cityName}, ${this.state.homeAddress.stateName}, ${this.state.homeAddress.zipCode}`}</Typography>
        </>
      )

    }
    else {
      return <Typography className="fill-details-error">Please enter your address details</Typography>
    }
  }

  handleBillingAddressSubHead = () => {
    if (this.isBillingAddressComplete() && this.state.useSameInformation) {
      return <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}><Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}><Typography>{this.handleCardNumber()}</Typography>
        <img className="cardIconStyle1" src={visaIcon} /></Box>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
          <Checkbox data-test-id="useSameInformation" checked={this.state.useSameInformation} onClick={this.useSameInformation} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Disabled_checkbox} style={{ width: "20px", height: "20px" }} />} disabled />
          <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#A8A29E" }}>Use the same as Home Address</Typography>
        </Box><Typography className="DisabledshowPolicy">{`${this.state.BillingAddress.homeAddress}, ${this.state.BillingAddress.cityName}, ${this.state.BillingAddress.stateName}`}</Typography>
      </Box >
    }
    else if (this.isBillingAddressComplete()) {
      return <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}> <Typography>{this.handleCardNumber()}</Typography>
          <img className="cardIconStyle1" src={visaIcon} /></Box>
        <Typography className="showPolicy">{`${this.state.BillingAddress.homeAddress}, ${this.state.BillingAddress.cityName}, ${this.state.BillingAddress.stateName}`}</Typography></Box >
    }
    else {
      return <Typography className="fill-details-error">Please enter your payment details</Typography>
    }
  }

  handleSubmitClass = () => {
    if (this.isBillingAddressComplete() && this.isHomeAddressComplete() && this.state.checkBox === true) {
      return "Button-style"
    }
    else {
      return "Button-style-disabled"
    }
  }

  importantAsteriskSymbol = () => {
    return <Typography className="asteriskSymbolClass">*</Typography>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <CustomStepper
          color="#FFFFFF"
          stepperSteps={this.state.stepperSteps}
          bgColor="#E46760"
          activeStep={3}
        />
        <Typography className="mainHeading">{configJSON.finalizeYourBooking}</Typography>
        <Box className="container">
          <Box className="leftBox">
            {this.state.openDetailedHomeAddress
              ? <Box className="opendedTopBox">
                <Box className="closeHomeAddressBox" onClick={this.handleCloseHomeAddress}>
                  <Box className="closeHomeAddressChildBox">
                    <Box className="submitDetails">
                      <img className="statusIcon" src={this.isHomeAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                      <Typography className="tabsTitle">{configJSON.homeAddress}</Typography>
                    </Box>
                    <KeyboardArrowUp />
                  </Box>
                  <Typography className="subhomeCart">{configJSON.homeAddressOpenDesc}</Typography>
                </Box>
                <Box className="inputHeadMain">
                  <Box className="inputHead">
                    <Box className="securityText" >
                      <Typography className='inputsLabel'>{configJSON.stateTxt}</Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>
                    <Box className="inputParent">
                      <input data-test-id="handleStateInHomeAddress" value={this.state.homeAddress.stateName} className='searchInput' onChange={(e) => { this.handleHomeAddress(e, "stateName") }} type="text" />
                      <img src={SearchIcon} className="searchIcon" alt="" />
                    </Box>
                  </Box>
                  <Box className="inputHead">
                    <Box className="securityText">
                      <Typography className='inputsLabel'>{configJSON.homeAddress} </Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>
                    <Box className="inputParent">
                      <input data-test-id="testInputForHomeAddress" value={this.state.homeAddress.homeAddress} className='searchInput' type="text" onChange={(e) => this.handleHomeAddress(e, "homeAddress")} />
                      <img src={SearchIcon} className="searchIcon" alt="" />
                      <img src={LocationIcon} className="locationIcon" alt="" />
                    </Box>
                  </Box>
                  <Box className="inputParentWithoutIcon">
                    <input data-test-id="handleHomeInHomeAddress" value={this.state.homeAddress.StreetAddress2} placeholder="Street Address Line 2" className='searchInput' type="text" onChange={(e) => this.handleHomeAddress(e, "StreetAddress2")} />
                  </Box>
                  <Box className="inputHeader">
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.cityNameTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input data-test-id="handleCityInHomeAddress" value={this.state.homeAddress.cityName} placeholder="Enter your City" className='searchInput' type="text" onChange={(e) => this.handleHomeAddress(e, "cityName")} />
                      </Box>
                    </Box>
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.zipCodeTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input
                          data-test-id="handleZipCodeForHomeAddress"
                          placeholder="000000"
                          maxLength={6}
                          value={this.handleValueForHomeAddressZipCode()}
                          className='searchInput'
                          type="number"
                          onInput={(e: React.FormEvent<HTMLInputElement>) => {
                            const inputElement = e.target as HTMLInputElement;
                            inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 6);
                            this.handleZipCodeForHomeAddress(e as React.ChangeEvent<HTMLInputElement>, "zipCode");
                          }} />
                      </Box>
                    </Box>
                  </Box>
                  <Button className="Button-style" data-test-id="handleCloseHomeAddressTest" onClick={this.handleCloseHomeAddress}>{configJSON.saveDetailsTxt}</Button>
                </Box>
              </Box>
              : <Box className="opendedTopBox">
                <Box data-test-id="handleOpenHome" className="openHomeBox" onClick={this.handleOpenHomeAddress}>
                  <Box className="submitDetailsParent">
                    <Box className="submitDetails">
                      <img
                        className="statusIcon"
                        src={this.isHomeAddressComplete() ? Submitted_details_icon : Submit_details_icon}
                      />
                      <Typography className="tabsTitle">{configJSON.homeAddress}</Typography>
                    </Box>
                    <Box className="filledDetailsBox">
                      <Typography className="showPolicy">{configJSON.detailsTxt}</Typography>
                      {this.handleHomeAddressSubHead()}
                    </Box>
                  </Box>
                  <KeyboardArrowDown />
                </Box>
              </Box>
            }
            {this.state.openDetailedBillingAddress
              ? <Box className="opendedTopBox">
                <Box style={{ textAlign: "start" }} onClick={this.handleCloseBillingAddress}>
                  <Box className="openedDetailBox">
                    <Box className="submitDetails">
                      <img className="statusIcon" src={this.isBillingAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                      <Typography className="tabsTitle">{configJSON.paymentDetailsTxt}</Typography>
                    </Box>
                    <KeyboardArrowUp />
                  </Box>
                  <Box className="questionsBox">
                    <Typography className="subPaymentCart">{configJSON.whyWeAskCreditTxt}</Typography>
                    <Typography className="learnMore">{configJSON.learnMoreTxt}</Typography>
                  </Box>
                </Box>
                <Box className="inputHeadMain">
                  <Typography className="billingHeader">{configJSON.cardDetailsTxt}</Typography>
                  <Box className="cardIconParentStyle">
                    <img className="cardIconStyle" src={VisaIcon} />
                    <img className="cardIconStyle" src={MasterCard} />
                    <img className="cardIconStyle" src={ApplePay} />
                    <img className="cardIconStyle" src={AmericanExpressIcon} />
                    <img className="cardIconStyle" src={Maestro} />
                    <img className="cardIconStyle" src={DiscoverIcon} />
                  </Box>

                  <Box className="inputHead">
                    <Box className="securityText">
                      <Typography className='inputsLabel'>{configJSON.cardNumberLabel}</Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>
                    <Box className="inputParentWithoutIcon">
                      <input data-test-id="handleCardNumber" placeholder="Placeholder" type="number" className='searchInput' value={this.state.cardDetails.cardNumber}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 16);
                          this.handleCardDetail(e as React.ChangeEvent<HTMLInputElement>, "cardNumber");
                        }} />
                      <img src={Card_number_icon} className="locationIcon" alt="" />

                    </Box>
                  </Box>
                  <Box className="inputHead">
                    <Box className="securityText">
                      <Typography className='inputsLabel'>{configJSON.nameOnCardTxt}</Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>
                    <Box className="inputParentWithoutIcon">
                      <input placeholder="Neelam Brar" data-test-id="handleCardName" value={this.state.cardDetails.nameOnCard} className='searchInput' type="text" onChange={(e) => { this.handleCardDetail(e, "nameOnCard") }} />
                    </Box>
                  </Box>
                  <Box className="inputHeader">
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.expirationDateTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input
                          type="text"
                          placeholder="MM / YY"
                          data-test-id="handleExpirationForCard"
                          className='searchInput'
                          value={this.state.cardDetails.expirationCode}
                          onInput={(e: React.FormEvent<HTMLInputElement>) => {
                            const inputElement = e.target as HTMLInputElement;
                            inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 10);
                            this.handleInputChange(e as React.ChangeEvent<HTMLInputElement>, "expirationCode");
                          }}
                        />
                        <img src={Date_icon} className="locationIcon" alt="" />
                      </Box>
                    </Box>
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.securityCodeTxt}</Typography>
                        <Typography className='security-subtext' >{configJSON.cvvTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input type="number" min={0} max={999}
                          data-test-id="handleCVV" value={this.handleValueForCVV()} style={{ letterSpacing: "5px" }} placeholder="000" className='searchInput' onInput={(e: React.FormEvent<HTMLInputElement>) => {
                            const inputElement = e.target as HTMLInputElement;
                            inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 3);
                            this.handleCVV(e as React.ChangeEvent<HTMLInputElement>, "securityCode");
                          }} />
                        <img src={Security_code_icon} className="locationIcon" alt="" />
                      </Box>
                    </Box>
                  </Box></Box>
                <Box className="inputHeadMain">
                  <Typography className="billingHeader">{configJSON.billingInformationTxt}</Typography>
                  <Box className="billing-checkbox">
                    <Checkbox data-test-id="useSameInformation" checked={this.state.useSameInformation} onClick={this.useSameInformation} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />
                    <Typography className="useSameInfor">{configJSON.useSameInformationTxt}</Typography>
                  </Box>
                  <Box className="inputHead">
                    <Box className="securityText">
                      <Typography className='inputsLabel'>{configJSON.stateTxt}</Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>

                    <Box className="inputParent">
                      <input data-test-id="handleStateForBilling" value={this.state.BillingAddress.stateName} className='searchInput' type="text" onChange={(e) => this.handleBillingAddress(e, "stateName")} />
                      <img src={SearchIcon} className="searchIcon" alt="" />
                    </Box>
                  </Box>
                  <Box className="inputHead">
                    <Box className="securityText">
                      <Typography className='inputsLabel'>{configJSON.homeAddress}</Typography>
                      {this.importantAsteriskSymbol()}
                    </Box>
                    <Box className="inputParent">
                      <input className='searchInput' data-test-id="handleBillingAddress" value={this.state.BillingAddress.homeAddress} type="text" onChange={(e) => this.handleBillingAddress(e, "homeAddress")} />
                      <img src={SearchIcon} className="searchIcon" alt="" />
                      <img src={LocationIcon} className="locationIcon" alt="" />
                    </Box>
                  </Box>
                  <Box className="inputParentWithoutIcon">
                    <input type="text" className='searchInput' placeholder="Street Address Line 2" value={this.state.BillingAddress.StreetAddress2} data-test-id="handleStreetForBilling" onChange={(e) => this.handleBillingAddress(e, "StreetAddress2")} />
                  </Box>
                  <Box className="inputHeader" >
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.cityNameTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input type="text" placeholder="Enter your City" className='searchInput' value={this.state.BillingAddress.cityName} data-test-id="handleCityForBilling" onChange={(e) => this.handleBillingAddress(e, "cityName")} />
                      </Box>
                    </Box>
                    <Box className="inputHead">
                      <Box className="securityText">
                        <Typography className='inputsLabel'>{configJSON.zipCodeTxt}</Typography>
                        {this.importantAsteriskSymbol()}
                      </Box>
                      <Box className="inputParentWithoutIcon">
                        <input placeholder="000000" className='searchInput' data-test-id="handleZIPForBilling" maxLength={6} value={this.handleValueForBillingAddressZipCode()} type="number"
                          onInput={(e: React.FormEvent<HTMLInputElement>) => {
                            const inputElement = e.target as HTMLInputElement;
                            inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 6);
                            this.handleZipCodeForBillingAddress(e as React.ChangeEvent<HTMLInputElement>, "zipCode");
                          }} />
                      </Box>
                    </Box>
                  </Box>
                  <Button className="Button-style" onClick={this.handleCloseBillingAddress}>{configJSON.saveDetailsTxt}</Button></Box>
              </Box>
              : <Box className="opendedTopBox">
                <Box data-test-id="testOpenBillingAddress" className="openBillingAddressBox" onClick={this.handleOpenBillingAddress}>
                  <Box className="submitDetailsParent"> <Box className="submitDetails">
                    <img className="statusIcon" src={this.isBillingAddressComplete() ? Submitted_details_icon : Submit_details_icon} />
                    <Typography className="tabsTitle">{configJSON.paymentDetailsTxt}</Typography>
                  </Box>
                    <Box className="filledDetailsBox">
                      <Typography className="showPolicy">{configJSON.detailsTxt}</Typography>
                      {this.handleBillingAddressSubHead()}
                    </Box>
                  </Box>
                  <KeyboardArrowDown />
                </Box>
              </Box>
            }
          </Box>
          <Box className='rightBox'>
            <Box className="rightBoxChild">
              <Box className="orderSummaryMain">
                <Typography className="orderSummary">{configJSON.orderSummaryTxt}</Typography>
                <Typography className="subhomeCart">{configJSON.reviewOrderTxt} <br />
                {configJSON.pressSubmitTxt}</Typography>
                <Box className="summaryBoxes">
                  <Box className="summaryProfile">
                    <Box className="profileParent">
                      <Box>
                        <img className="profileImageStyle" src={ProfileImage} />
                      </Box>
                      <Box>
                        <Typography className="showPolicy1">{configJSON.providerTxt}</Typography>
                        <Typography className="orderSummaryHeader1">{configJSON.meredithGreyTxt}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">{configJSON.durationTxt}</Typography>
                    <Typography className="orderSummarySubHeader">{configJSON.sixtyMinutesTxt}</Typography>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">{configJSON.startTimeTxt}</Typography>
                    <Typography className="orderSummarySubHeader">{configJSON.oneThirtyPmCstTxt}</Typography>
                  </Box>
                  <Box className="summaryProfile">
                    <Typography className="orderSummaryHeader">{configJSON.dateTxt}</Typography>
                    <Typography className="orderSummarySubHeader">{configJSON.wednesdayAugust15th2024Txt}</Typography>
                  </Box>
                </Box>
                <Box className="summaryBoxes">
                  <Box className="summaryProfile1">
                    <Typography className="orderSummarySubHeader1">{configJSON.subtotalTxt}</Typography>
                    <Typography className="orderSummarySubHeader1">{configJSON.oneHundredFiftyDollarsTxt}</Typography>
                  </Box>
                  <Box className="summaryProfile1">
                    <Typography className="orderSummarySubHeader1">{configJSON.taxesTxt}</Typography>
                    <Typography className="orderSummarySubHeader1">{configJSON.thirtyDollarsThirtyFiveCentsTxt}</Typography>
                  </Box>
                  <Box className="summaryProfile1">
                    <Typography className="orderSummaryHeader1">{configJSON.totalTxt}</Typography>
                    <Typography className="orderSummaryHeader1">{configJSON.oneHundredEightyDollarsThirtyFiveCentsTxt}</Typography>
                  </Box>
                </Box>
                <Box className="summaryBoxes">
                  <Box className="questionsBox">
                    <Box className="Coupon-box">
                      <Typography className="orderSummaryHeader2">{configJSON.couponCodeTxt}</Typography>
                      <Typography className="orderSummarySubHeader2">{configJSON.enterCouponTxt}</Typography>
                    </Box>
                    <Box>
                      <Box className="inputParent1">
                        <input data-test-id="handleState" className='searchInput' placeholder="__________" style={{ textAlign: "center", maxWidth: "192px", width: "100%", letterSpacing: "5px", paddingInline: "10px" }} type="text" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="billingCheckCheckbox">
                  <Checkbox data-test-id="testCheckBox" onClick={this.handleCheckBox} icon={<img src={CheckboxIcon} style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={Checkbox_checked} style={{ width: "20px", height: "20px" }} />} />
                  <Typography className="showPolicy">{configJSON.acceptTxt}</Typography>
                  <Typography className="showPolicyAlert">{configJSON.noShowPolicyTxt}</Typography>
                </Box>
                <Button data-test-id="testSubmit" className={this.handleSubmitClass()} onClick={this.submitBooking}>Submit</Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <StyledModal
          aria-labelledby="customized-dialog-title"
          open={this.state.isThereModalOpen}
        >
          <DialogContent dividers>
            <Typography className="modalTitle">{configJSON.areYouStillHereTitle}</Typography>
            <Typography className="ifYouHaveQuestionTxt">{configJSON.ifYouHaveQuestionTxt}</Typography>
            <Box className="questionHelpNumberBox">
              <Typography className="questionHelpNumber">{configJSON.questionHelpNumber}</Typography>
              <img src={phoneCallIcon} alt="" />
            </Box>
            <Button className="closeBtn" data-test-id="closeThereModal" onClick={this.closeThereModal}>{configJSON.ImStillHereTxt}</Button>
          </DialogContent>
        </StyledModal>

        <StyledModal
          aria-labelledby="customized-dialog-title"
          open={this.state.isErrorModalOpen}
        >
          <DialogContent dividers>
            <Typography className="errorModalTitle">{configJSON.thereWasErrorFirstLineTxt}</Typography>
            <Typography className="errorModalTitle">{configJSON.thereWasErrorSecondLineTxt}</Typography>

            <img className="errorModalCardIcon" src={cardErrorIcon} alt="" />

            <Typography className="errorModalDesc">{configJSON.errorModalDesc1}</Typography>
            <Typography className="errorModalDesc">{configJSON.pleaseTxt} <span className="errorModalContactUsSpan">{configJSON.errorModalDesc2}</span></Typography>
            <Typography className="errorModalDesc">{configJSON.errorModalDesc3}</Typography>
            
            <Button className="closeBtn" data-test-id="closeErrorModal" style={{padding: "16.5px 67.5px"}} onClick={this.closeErrorModal}>{configJSON.tryAgainTxt}</Button>
          </DialogContent>
        </StyledModal>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  "& .mainHeading": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    color: "#2C2524",
    marginTop: "60px",
  },
  "& .filledDetailsBox": { 
    display: "flex",
    flexDirection: "column",
    gap: "2px"
  },
  "& .fill-details-error": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#DC2626"
  },
  "& .submitDetails": { 
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: "272px",
    alignItems: "center" 
  },
  "& .submitDetailsParent": { 
    display: "flex",
    flexDirection: "row",
    gap: "24px" 
  },
  "& .statusIcon": { 
    height: "32px",
    width: "32px" 
  },
  "& .showPolicy": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#2C2524",
    lineHeight: "24px",
  },
  "& .DisabledshowPolicy": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#A8A29E" 
  },
  "& .showPolicy1": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#2C2524",
    textAlign: "start" 
  },
  "& .showPolicyAlert": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#E46760",
    textDecoration: "underline" 
  },
  "& .questionsBox": { 
    display: "flex",
    flexDirection: "row",
    gap: "12px" 
  },
  "& .profileParent": { 
    display: "flex",
    flexDirection: "row",
    gap: "56px" 
  },
  "& .Coupon-box": { 
    display: "flex",
    flexDirection: "column" 
  },
  "& .summaryBoxes": { 
    paddingBlock: "24px",
    borderBottom: "1px solid #D6D3D1",
    display: "flex",
    flexDirection: "column",
    gap: "12px" 
  },
  "& .orderSummaryMain": { 
    textAlign: "center",
    paddingInline: "24px" 
  },
  "& .orderSummaryHeader": { 
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#2C2524",
    maxWidth: "112px",
    width: "100%",
    textAlign: "start" 
  },
  "& .cardIconStyle": { 
    height: "30px",
    width: "50px" 
  },
  "& .cardIconStyle1": { 
    height: "10px",
    width: "30px",
    alignItems: "center",
    position: "relative",
    bottom: "2px" 
  },
  "& .cardIconParentStyle": { 
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    justifyContent: "end" 
  },
  "& .MuiStepIcon-root.Mui-active": { 
    color: "#E46760",
    height: "32px",
    width: "32px" 
  },
  "& .MuiStepIcon-root.Mui-completed": { 
    color: "#1A4A42",
    height: "32px",
    width: "32px" 
  },
  "& .orderSummaryHeader1": { 
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
    color: "#2C2524" 
  },
  "& .orderSummaryHeader2": { 
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#2C2524",
    textAlign: "start" 
  },
  "& .orderSummarySubHeader": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#78716C" 
  },
  "& .orderSummarySubHeader2": { 
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Poppins",
    color: "#78716C",
    textAlign: "start" 
  },
  "& .orderSummarySubHeader1": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#57534E" 
  },
  "& .orderSummary": { 
    fontWeight: 500,
    fontSize: "29px",
    fontFamily: "Poppins",
    color: "#2C2524" 
  },
  "& .inputParent": { 
    position: 'relative',
    border: '1px solid #E6C79C',
    borderRadius: 30,
    overflow: 'hidden',
    paddingLeft: 38,
    paddingBlock: 12 
  },
  "& .inputParentWithoutIcon": { 
    position: 'relative',
    border: '1px solid #E6C79C',
    borderRadius: 30,
    overflow: 'hidden',
    paddingLeft: 16,
    paddingBlock: 12 
  },
  "& .inputParent1": { 
    position: 'relative',
    border: '1px solid #E6C79C',
    borderRadius: 30,
    overflow: 'hidden',
    paddingBlock: 12 
  },
  "& .inputHeader": { 
    display: "flex",
    flexDirection: "row",
    gap: "10px" 
  },
  "& .billingHeader": { 
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
    color: "#2C2524",
    textAlign: "center",
    borderTop: "1px solid #D6D3D1",
    paddingTop: "20px",
    marginTop: "16px" 
  },
  "& .searchIcon": { 
    width: 24,
    position: 'absolute',
    top: 10,
    left: 10 
  },
  "& .locationIcon": { 
    width: 24,
    position: 'absolute',
    top: 10,
    right: 10 
  },
  "& .billing-checkbox": { 
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center" 
  },
  "& .billingCheckCheckbox": { 
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    marginTop: "24px" 
  },
  "& .useSameInfor": {
    position: "relative",
    top: "3px"
  },
  "& .profileImageStyle": { 
    height: "56px",
    width: "56px",
    borderRadius: "100px" 
  },
  "& .Button-style": { 
    background: "#E46760",
    color: "#FFFAF6",
    borderRadius: "24px",
    height: "56px",
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Raleway",
    width: '100%',
    marginTop: 24
  },
  '& .Button-style:hover': { 
    background: "#E46760",
    color: "#FFFAF6" 
  },
  "& .Button-style-disabled": { 
    background: "#E7E5E4",
    color: "#A8A29E",
    borderRadius: "24px",
    height: "56px",
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Raleway",
    width: '100%',
    marginTop: 24
  },
  "& .Button-style-disabled:hover": { 
    background: "#E7E5E4",
    color: "#A8A29E" 
  },
  "& .inputHeadMain": {
    display: "flex", 
    flexDirection: "column", 
    gap: "20px", 
    paddingInline: "36px"
  },
  "& .inputHead": { 
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    flex: "1" 
  },
  "& .inputsLabel": { 
    textAlign: "start",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#2C2524" 
  },
  "& .summaryProfile": { 
    display: "flex",
    flexDirection: "row" 
  },
  "& .summaryProfile1": { 
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between" 
  },
  "& .asteriskSymbolClass": { 
    textAlign: "start", 
    fontWeight: 700, 
    fontSize: "16px", 
    fontFamily: "Poppins", 
    color: "#DC2626" 
  },
  '& .searchInput:hover, .searchInput:focus': { 
    border: 'none !important', 
    outline: 'none !important' 
  },
  '& .searchInput::placeholder': { fontWeight: "400px",
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#A8A29E" 
  },
  "& .searchInput": { width: '100%',
    border: 'none',
    backgroundColor: "transparent" 
  },
  "& .learnMore": { fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#57534E",
    textDecoration: "underline" 
  },
  "& .securityText": { display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  "& .security-subtext": { fontWeight: "400px",
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#78716C" 
  },
  "& .opendedTopBox": { display: "flex",
    flexDirection: "column",
    gap: "20px",
    border: "1px solid #A8A29E",
    borderRadius: "24px",
    paddingInline: "16px",
    paddingBlock: "24px",
    marginBottom: "52px",
    backgroundColor: "white" 
  },
  "& .openBillingAddressBox": {
    display: "flex",
    flexDirection: "row",
    textAlign: "start",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .openedDetailBox": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .openHomeBox": {
    display: "flex",
    flexDirection: "row",
    textAlign: "start",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .closeHomeAddressBox": {
    alignItems: "center",
    textAlign: "start"
  },
  "& .closeHomeAddressChildBox": { 
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center" 
  },
  "& .rightBoxChild": { 
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    border: "1px solid #A8A29E",
    borderRadius: "24px",
    paddingBlock: "24px",
    marginBottom: "52px" 
  },
  "& .subStateSelect": { 
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Poppins",
    color: "#78716C" 
  },
  "& .tabsTitle": {
    fontWeight: 400,
    fontSize: "24px",
    fontFamily: "Poppins",
    color: "#2C2524",
    lineHeight: "36px",
  },
  "& .subhomeCart": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#78716C" },
  "& .subPaymentCart": { 
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#57534E" 
  },
  "& .container": {
    display: "flex",
    gap: 24,
    maxWidth: "82%",
    margin: "auto",
    width: "100%",
    height: "auto",
    flex: 1,
    marginTop: "60px",
    "@media (max-width:960px)": {
      flexDirection: "column"
    }
  },
  "& .rightBox": { height: "auto", width: "100%", maxWidth: '40%', backgroundColor: '#FFF', "@media (max-width:960px)": { maxWidth: "95%", margin: "auto" } },
  "& .leftBox": {
    height: "auto", width: "100%", maxWidth: '60%', textAlign: "center", display: "flex", flexDirection: "column",
    "& .image": {
      width: "100%",
      height: "auto",
      maxWidth: "373.89px",
      maxHeight: "337.08px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flex: 1
    },
    '& .stepper-width': {
      maxWidth: "600px",
      width: '100%',
      fontfamily: "Poppins",
      margin: "0 auto"
    },
    "@media (max-width:960px)": { maxWidth: "95%", margin: "auto" }
  },
  "& .heading": { 
    paddingBlock: "36px 28px",
    justifyContent: "center" 
  },
  "& .MuiCheckbox-root.Mui-checked": { 
    color: "#1A4A42",
    height: "20px",
    width: "20px" 
  },
  "& .MuiCheckbox-root": { 
    height: "20px",
    width: "20px" 
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": { 
    color: "#1A4A42",
    height: "32px",
    width: "32px" 
  },
  '& .MuiStep-root.MuiStep-horizontal': { 
      minWidth: 'max-content' 
    },
  '& .MuiStepConnector-horizontal': { 
      top: '15px !important',
    },
  "& .MuiTab-root.Mui-selected": { 
      color: "#E46760" 
    },
});

const StyledModal = styled(Dialog)({
  textAlign: "center",
  "& .MuiDialog-paper": {
    boxSizing: 'border-box',
    borderRadius: "24px",
    width: '600px',
  },
  '& .MuiDialogContent-root': {
    paddingBottom: '24px',
    paddingTop: '32px',
  },
  "& .modalTitle": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "140%",
    letterSpacing: "0.5px",
    color: "#2C2524",
  },
  "& .ifYouHaveQuestionTxt": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0px",
    color: "#2C2524",
    marginTop: "40px",
  },
  "& .questionHelpNumberBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "32px",
  },
  "& .questionHelpNumber": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0px",
    marginRight: "8px",
  },
  "& .closeBtn": {
    padding: "16.5px 52px",
    marginTop: "72px",
    fontFamily: "Raleway",
    textTransform: "capitalize",
    letterSpacing: "1px",
    fontSize: "20px",
    borderRadius: "24px",
    fontWeight: 700,
    lineHeight: "100%",
    backgroundColor: "#E46760",
    color: "#FFFAF6",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#B6524D",
    },
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "10px 120px",
    },
  },

  "& .errorModalTitle": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "140%",
    letterSpacing: "0.5px",
    color: "#2C2524",
  },
  "& .errorModalDesc": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .errorModalContactUsSpan": {
    color: "#E46760",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "& .errorModalCardIcon": {
    margin: "40px 0 40px 0",
  },
});
// Customizable Area End
