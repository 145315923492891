// Customizable Area Start
import React from "react";
import { Badge, Box, Button, Menu, MenuItem, styled, Typography, } from "@mui/material";
import ChooseProviderController, { Props, configJSON } from "./ChooseProviderController.web";
import CustomStepper from "../components/CustomStepper.web";
import FilterListIcon from '@mui/icons-material/FilterList';
import CareTeamHeader from "../components/CareTeamHeader";
import { backArrowIcon, } from "../assets";
import { KeyboardArrowDown, } from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// Customizable Area End

export default class ChooseProvider extends ChooseProviderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const sortByOpen = Boolean(this.state.sortByAnchor);
    // Customizable Area End
    return (
      // Customizable Area Start
      <CustomWrapper ref={this.pageScrollTopRef}>
        <SidebarLine />
        <CareTeamHeader />
        <Box className='container'>
          <Box className='leftBox'>
            <CustomStepper
              activeStep={2}
              stepperSteps={this.state.stepperStep}
              color="#FFFFFF"
              bgColor="#E46760"
            />
            <Box className="topActionsBtnBox">
              <Button className="backBtn" data-test-id="handleBackBtn" onClick={this.handleBack}> <img src={backArrowIcon} alt="" />{configJSON.backText}</Button>
              <Typography className="chooseProviderTxt">{configJSON.chooseProviderHeading}</Typography>
              <Box className='filtericon'>
                <Box>
                  <Badge badgeContent={1} >
                    <FilterListIcon />
                  </Badge>
                </Box>
              </Box>
            </Box>
            <Box className="recommendedBox">
              <Typography className="sortByTxt">{configJSON.sortByText}</Typography>
              <Box
                onMouseDown={this.handleOpenSortBy}
                className="recommendClickBox"
                data-test-id="openSortByTest"
              >
                <Typography className="recommendedTxt">{this.state.sortByInitial}</Typography>
                <KeyboardArrowDown style={{ marginLeft: "6px", }} />
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={this.state.sortByAnchor}
                open={sortByOpen}
                onClose={this.handleCloseSortBy}
                data-test-id="closeSortByTest"
                disableAutoFocusItem
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                slotProps={{
                  paper: {
                    style: {
                      marginTop: "5px",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #E6C79C",
                      borderRadius: "24px",
                      boxShadow: "none",
                    },
                  },
                }}
              >
                {
                  this.state.sortByMenuItems && this.state.sortByMenuItems.map((menu) => {
                    return (
                      <MenuItem
                        key={menu}
                        data-test-id="setSortByTest"
                        onClick={() => this.handleSetSortBy(menu)}
                        sx={{
                          fontFamily: "Poppins",
                          padding: "8px 57px 8px 16px",
                          color: "#44403C",
                          fontWeight: 400,
                          "&:hover": {
                            backgroundColor: "#F5F5F4",
                            color: "#2C2524",
                          },
                        }}
                      >{menu}</MenuItem>
                    )
                  })
                }
              </Menu>
            </Box>

            <Box className="providerBox">
              {this.state.providerList.map((providerItem, index) => (
                <Box className="provider" key={index} style={this.getProviderStyle(providerItem.id)}>
                  <Box className="providerTopSection">
                    <img className="cardProfile" src={providerItem.profileImg} alt="provider-image" />
                    <Box className="providerDetails">
                      <Typography className="providerName">{providerItem.name}</Typography>
                      <Typography className="fullProfileBtn">Full Profile</Typography>
                      <Typography className="LMFTTxt">{providerItem.qualification}</Typography>
                      <Box className="LMFTList">
                        {providerItem.specialties.map((specialty, i) => (
                          <Typography className="LMFTListItem" key={i}>{specialty}</Typography>
                        ))}
                      </Box>
                      <Typography className="priceTitle">Initial session price:</Typography>
                      <Typography className="price">
                        ${providerItem.initialSessionPrice} <span className="priceSpan">({providerItem.sessionDuration})</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className="providerDivider"></Typography>
                  <Box className="providerBottomSection">
                    <Box className="providerBottomLeftBox">
                      <Typography className="nextAvailability">Next availability:</Typography>
                      <Typography className="nextAvailabilityTxt">{providerItem.nextAvailability}</Typography>
                    </Box>
                    <Button
                      data-test-id="selectProviderTest"
                      className={this.getProviderButtonClass(providerItem.id)}
                      onClick={() => this.selectProvider(providerItem.id)}
                    >
                      {this.getProviderButtonText(providerItem.id)}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>



            <Button className="loadMoreBtn">{configJSON.loadMoreBtnTxt}</Button>
            <Typography className="joinWaitlistTxt" data-test-id="joinWaitListTest" onClick={this.joinWaitList}>{configJSON.joinWaitlistTxt}</Typography>
          </Box>
          <Box className='rightBox'>

            <Box className="rightBoxTop">
              <Typography className="chooseADateTxt" style={{ color: this.getTextColor() }}>{configJSON.chooseADateTxt}</Typography>
              <Typography className="centralTimeTxt" style={{ color: this.getTextColor() }}>{configJSON.centralTimeTxt} <span className="centralTimeTxtSpan">{configJSON.utcTxt}</span><KeyboardArrowDown /> </Typography>
            </Box>
            <Typography className="rightBoxDivider"></Typography>

            <Box className="timeOfDayBoxMain">
              <Box className="timeOfDayInnerBox" style={{ backgroundColor: this.getTimeOfDayBoxBg() }}>
                {
                  this.state.timeOfDay.map((time) => {
                    return (
                      <Box
                        key={time.time}
                        className="timeOfDay"
                        style={this.getTimeOfDayStyles(time.time)}
                        data-test-id="setDayTimeTest"
                        onClick={() => this.setDayTime(time.time)}
                      >
                        <img src={this.getTimeOfDayImage(time)} alt="" />
                        <Typography className="timeOfDayTxt" style={this.getTimeOfDayTextStyle(time)}>{time.time}</Typography>
                      </Box>
                    )
                  })
                }
              </Box>
            </Box>

            <Box className='calender-box'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  open
                  className="datePicker"
                  label="Select Date"
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  format="DD/MM/YYYY"
                  disabled={!this.state.selectedId}
                  dayOfWeekFormatter={(dayOfWeek) => {
                    const weekdaysFull = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    return weekdaysFull[dayOfWeek as any];
                  }}
                  disablePast
                  data-test-id="handleDateChangeTest"
                  slotProps={{
                    popper: {
                      sx: {
                        ".MuiPaper-root": {
                          border: "1px solid #D6D3D1",
                          borderRadius: "8px",
                          overflowX: "hidden",
                        },
                        ".MuiPickersCalendarHeader-label": {
                          fontFamily: "Poppins !important",
                          fontWeight: 700,
                          fontSize: "14px !important",
                          lineHeight: "22px !important",
                          letterSpacing: "0% !important",
                          color: "#1C1917 !important",
                        },
                        ".MuiDayCalendar-weekDayLabel": {
                          color: "#292524 !important",
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "22px",
                          letterSpacing: "0%",
                        },
                      },
                    },
                    day: {
                      sx: {
                        fontSize: "14px !important",
                        "&.Mui-selected": {
                          backgroundColor: "#E46760 !important",
                          color: "#FFFFFF !important",
                          fontWeight: 700,
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>

            {
              this.state.selectedDate
                ? <Box className="bottomTimeSelectionMain">
                  <Typography className="dayAndMonthTxt">{this.state.dateString}:</Typography>
                  <Box className="bottomTimeSelectionBox">
                    {
                      this.state.chooseTimeList && this.state.chooseTimeList.map((time) => {
                        return (
                          <Button key={time}
                            className="chooseTime"
                            data-test-id="setSelectedTimeTest"
                            onClick={() => this.setSelectedTime(time)}
                            style={this.getChooseTimeButtonStyle(time)}
                          >
                            {time}
                          </Button>
                        )
                      })
                    }
                  </Box>
                </Box>
                : null
            }
          </Box>

        </Box>
        {
          this.isValidForSession() && <Box className="confirmSessionMainBox">
            <Button className="cancelBtn" data-test-id="cancelTest" onClick={this.handleCancel}>{configJSON.cancelTxt}</Button>
            <Button className="confirmSessionBtn" data-test-id="confirmSessionTest" onClick={this.handleConfirmSession}>{configJSON.confirmSessionTxt}</Button>
          </Box>
        }
      </CustomWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  position: "relative",
  "& .container": {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    marginLeft: "32px",
    boxSizing: "border-box",
  },
  "& .leftBox": {
    minHeight: "100vh",
    width: "65%",
    boxSizing: "border-box",
    paddingBottom: "150px",
  },
  "& .topActionsBtnBox": {
    width: "82%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "60px",
  },
  "& .backBtn": {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "#FFFFFF",
    padding: "10px 16px",
    display: "flex",
    alignItems: "center",
    border: "2px solid #E7E5E4",
    borderRadius: "16px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    color: "#A8A29E",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
  "& .backBtn img": {
    marginRight: "8px",
  },
  "& .chooseProviderTxt": {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    color: "#2C2524"
  },

  "& .filtericon": {
    position: "relative", cursor: "pointer"
  },
  "& .filtericon .MuiSvgIcon-root": {
    backgroundColor: "#1A4A42",
    color: "#FFFFFF",
    borderRadius: "16px",
    height: "24px",
    width: "24px",
    padding: 10,
    cursor: "pointer"
  },
  "& .MuiBadge-badge": {
    color: "#E46760",
    backgroundColor: "#E46760",
    top: 3,
    right: 3,
    minWidth: 16,
    width: 16,
    height: 16
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#E46760",
    height: "32px",
    width: "32px"
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#1A4A42",
    height: "32px",
    width: "32px"
  },
  "& .recommendedBox": {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    marginTop: "37px",
  },
  "& .sortByTxt": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .recommendClickBox": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .recommendedTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
    marginLeft: "8px",

  },
  "& .loadMoreBtn": {
    backgroundColor: "#E46760",
    display: "block",
    width: "fit-content",
    margin: "auto",
    marginTop: "27px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    borderRadius: "24px",
    color: "#FFFFFF",
    padding: "16px 123px",
    textTransform: 'capitalize',
    "@media (max-device-width: 745px)": {
      padding: "8px 70px",
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: "#B6524D",
      color: "#FFFFFF",
    }
  },
  "& .joinWaitlistTxt": {
    width: "fit-content",
    margin: "auto",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationOffset: "Auto",
    textDecorationThickness: "Auto",
    color: "#78716C",
    marginTop: "16px",
    cursor: "pointer",
  },
  "& .providerBox": {
    width: "82%",
    margin: 'auto',
    boxSizing: "border-box",
    marginTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "24px",
  },
  "& .provider": {
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
  },
  "& .providerTopSection": {
    display: "flex",
    alignItems: "center",
    padding: "14px 12px 0px 10px",
  },
  '& .cardProfile': {
    width: '127px',
    height: '127px',
    borderRadius: '50%',
    border: "5px solid #F2F0EE",
  },
  "& .providerDetails": {
    marginLeft: "20px",
  },
  "& .providerName": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
    color: "#292524",
  },
  "& .fullProfileBtn": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#E46760",
  },
  "& .LMFTTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
    marginTop: "8px",
  },
  "& .LMFTList": {
    marginTop: "4px",
    display: "flex",
    flexWrap: "wrap",
    gap: "2px",
  },
  "& .LMFTListItem": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#203447",
    backgroundColor: "#E9EBED",
    padding: "4px 8px",
    borderRadius: "16px",
  },
  "& .priceTitle": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
    marginTop: "8px",
  },
  "& .price": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
    letterSpacing: "0px",
    color: "#292524",
  },
  "& .priceSpan": {
    color: "#78716C",
  },
  "& .providerDivider": {
    width: "100%",
    border: "1px solid #E7E5E4",
    margin: "12px 0 12px 0",
    boxSizing: "border-box",
  },
  "& .providerBottomSection": {
    padding: "0 14px 14px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .providerBottomLeftBox": {
    display: "flex",
    alignItems: "center",
  },
  "& .nextAvailability": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#78716C",
  },
  "& .nextAvailabilityTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#292524",
    marginLeft: "6px",
  },
  "& .selectProvderBtn": {
    minWidth: "140px",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    borderRadius: "26px",
    padding: "4px 0 4px 0",
    textTransform: 'capitalize',
    border: "2px solid #E46760",
    color: "#E46760",
    "&:hover": {
      color: "#E46760",
    }
  },
  "& .selectedProvderBtn": {
    borderRadius: "26px",
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontSize: "16px",
    letterSpacing: "0px",
    minWidth: "140px",
    padding: "4px 0 4px 0",
    border: "2px solid #E46760",
    color: "#FFFFFF",
    backgroundColor: "#E46760",
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: "#E46760",
      color: "#FFFFFF",
    }
  },
  "& .rightBox": {
    minHeight: "100vh",
    width: "32.91%",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    paddingBottom: "150px",
  },
  "& .rightBoxTop": {
    width: "364px",
    padding: "34px 0 0 42px",
  },
  "& .chooseADateTxt": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "40.6px",
    letterSpacing: "0.5px",
    textAlign: "center",
  },
  "& .centralTimeTxt": {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    marginTop: "32px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
  },
  "& .centralTimeTxtSpan": {
    fontWeight: 400,
    margin: "0 6px 0 8px",
  },
  "& .rightBoxDivider": {
    border: "1px solid #D6D3D1",
    width: "100%",
    margin: "12px 0 24px 0",
  },
  "& .timeOfDayBoxMain": {
    width: "364px",
    padding: "0 0 0 42px",
  },
  "& .timeOfDayInnerBox": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 4px",
    border: "1px solid #D6D3D1",
    borderRadius: "50px",
  },
  "& .timeOfDay": {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: "50px",
    cursor: "pointer",
  },
  "& .timeOfDayTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    marginLeft: "6px",
  },
  '& .calender-box': {
    margin: "24px 0 0 42px",
    minHeight: "400px",
  },
  "& .datePicker": {
    maxWidth: 425,
    width: "100%",
    marginTop: -50,
    visibility: 'hidden',
  },
  "& .bottomTimeSelectionMain": {
    width: "364px",
    margin: "28px 0 0 42px",
    boxSizing: "border-box",
  },
  "& .dayAndMonthTxt": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#2C2524",
  },
  "& .bottomTimeSelectionBox": {
    width: "100%",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "12px 4px",
    marginTop: "12px",
  },
  "& .chooseTime": {
    border: "2px solid #E46760",
    fontFamily: "Poppins",
    fontSize: "16px",
    leadingTrim: "Cap height",
    lineHeight: "24px",
    letterSpacing: "0px",
    padding: "14.5px 0",
    textAlign: "center",
    borderRadius: "26px",
    cursor: "pointer",
  },
  "& .confirmSessionMainBox": {
    width: "100%",
    backgroundColor: "#1A4A42",
    padding: "43px 104px 46px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "24px",
    borderTop: "4px solid #E7E5E4",
    position: "fixed",
    bottom: 0,
    boxSizing: "border-box",
  },
  "& .cancelBtn": {
    width: "300px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    padding: "16.5px 0",
    borderRadius: "24px",
    color: "#D1DBD9",
    backgroundColor: "#153B35",
    border: "2px solid #76928E",
    "&:hover": {
      color: "#D1DBD9",
      backgroundColor: "#153B35",
    },
  },
  "& .confirmSessionBtn": {
    width: "300px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    borderRadius: "24px",
    padding: "16.5px 0",
    backgroundColor: "#E46760",
    color: "#FFFAF6",
    "&:hover": {
      color: "#FFFAF6",
      backgroundColor: "#E46760",
    },
  },
});

const SidebarLine = styled(Box)({
  width: "32px",
  backgroundColor: "#1A4A42",
  height: "100vh",
  position: "fixed",
});
// Customizable Area End
