import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../landingpage/src/assets';
import {
  Box,
  Button,
  styled,
  Typography, Step, Stepper, StepLabel,
  TextField, Checkbox, MenuItem
} from '@mui/material';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, "Only alphabets are allowed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
});
// Customizable Area End

import PatientRepresentativeController, {
  Props
} from "./PatientRepresentativeController.web";

export default class PatientRepresentativeDetail extends PatientRepresentativeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {

    return (
      // Customizable Area Start
      <StyledWrapper>
        <Box style={{height:'100vh',display:'flex',flexDirection:'column',justifyContent:'space-between',
          backgroundColor:'#FFFAF6'
        }}>

        <section className='leftBorder'></section>
        <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
          <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
         </Box>
         <Box className="mainContainer">
          <Stepper alternativeLabel className="stepper">
            {this.state.stepper.map((label, index) => (
              <Step key={label}
                sx={{
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#E46760',
                  },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: '#FFFFFF',
                  },
                }}>
                <StepLabel>
                  <Typography sx={index == 0 ? { fontFamily: 'Poppins', fontSize: '16px', fontWeight: 700, color: '#2C2524' } : {}}>

                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box className="headingContainer">
            <Typography className="mainHeading">Authorized Representative Details</Typography>
          </Box>
          <Box className='subHeadingContainer'>
            <Typography className="subHeading">Please enter your details so we can get started.</Typography>

          </Box>
       {!this.state.continue?
        <>
          <Box className='mobileNumberContainer'>
            <Typography className='mobileNumber'>Email <span className="mandotary">*</span></Typography>
            <TextField fullWidth variant="outlined" name="mobile" data-test-id="email"
              onChange={this.handleValidation}
              className="mobileNumberTextfield"
              placeholder="example@gmail.com" />
          {this.state.emailValid&&<Typography sx={{color:'red',fontSize:'12px'}}>enter valid email</Typography>}
          </Box>
          <Box className='prefered-contacts'>
            <Typography className='mobileNumber'>Preferred Contact Method<span className="mandotary">*</span>
              <span className="non-mandotary">(select all that apply)</span></Typography>
            <Box className='checkBox-container'>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Checkbox data-test-id="checkbox"icon={
                    <Box className="checkbox" />}
                  checkedIcon={<Box className="checkedIcon"><CheckIcon sx={{ color: "white", fontSize: 16 }} /></Box>
                  }
                  sx={{padding: 0,}}checked={this.state.contactMethod.includes('phone')}onChange={() => this.handleCheckboxChange('phone')}/> <Typography className="no-mobile">Phone</Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Checkbox data-test-id="checkbox" icon={<Box className="checkbox" />}checkedIcon={<Box className="checkedIcon"><CheckIcon sx={{ color: "white", fontSize: 16 }} /></Box>}sx={{padding: 0,}} checked={this.state.contactMethod.includes('email')}onChange={() => this.handleCheckboxChange('email')}/> <Typography className="no-mobile">Email</Typography>

              </Box>
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Checkbox data-test-id="checkbox" icon={<Box className="checkbox" />} checkedIcon={<Box className="checkedIcon"><CheckIcon sx={{ color: "white", fontSize: 16 }} /></Box>}sx={{padding: 0,}} checked={this.state.contactMethod.includes('sms')}onChange={() => this.handleCheckboxChange('sms')}/> <Typography className="no-mobile">SMS</Typography></Box>       
              
                </Box>
              {this.state.contactMethod.length===0&&<Typography sx={{color:'red',fontSize:'10px',mt:'10px'}}>Please select atleast one</Typography>}

          </Box>
          <Box sx={{ marginTop: '24px', width: '600px' }}>
            <Typography className="mobileNumber">Relationship to the Patient <span className="mandotary">*</span></Typography>
            <TextField fullWidth variant="outlined" placeholder="Select one" select
              value={this.state.Title || ""}
              onChange={(e) => this.setState({ Title: e.target.value })}
              defaultValue='Brother.'
              data-test-id="selecttitle"
            >
              {['Brother', 'Sister', 'Mother', 'Father', 'Other'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
              {this.state.Relation&&<Typography sx={{color:'red',fontSize:'10px',mt:'10px'}}>please select an option</Typography>}
          </Box>

          <Box sx={{ marginTop: '24px', width: '600px' }}>
            <Typography className="mobileNumber">Why are you signing up on behalf of someone else? <span className="mandotary">*</span></Typography>
            <TextField fullWidth variant="outlined" placeholder="Select one" select
              value={this.state.RepresentativeCause || ""}
              onChange={(e) => this.setState({ RepresentativeCause: e.target.value })}
              defaultValue='Other (specify)'
              data-test-id="representative"
            >
            
              {this.state.RepresentativeReason.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            {this.state.Representative&&<Typography sx={{color:'red',fontSize:'10px',mt:'10px'}}>please select an option</Typography>}
          </Box>
          <Box className='agreement-box' sx={{ marginTop: '32px' }}>
            <Checkbox data-test-id="accept"  onClick={()=>this.setState({acceptAggrementCheckbox:!this.state.acceptAggrementCheckbox})} icon={
              <Box
                className="checkbox" />
            }
              checkedIcon={
                <Box className="checkedIcon"
                >
                  <CheckIcon sx={{ color: "white", fontSize: 16 }} />
                </Box>
              }
              sx={{
                padding: 0,
              }} />
            <Typography className="agreement-text">I agree with the{' '}
              <span className="underline-text">Terms of Use</span>{' '}
              and{' '}
              <span className="underline-text">Privacy Policy.</span>
            </Typography>

          </Box>
          <Box className='agreement-box' sx={{ marginTop: "16px" }}>
            <Checkbox data-test-id="agree" onClick={()=>this.setState({patientConcent:!this.state.patientConcent})}
            icon={
              <Box
                className="checkbox" />
            }
              checkedIcon={
                <Box className="checkedIcon"
                >
                  <CheckIcon sx={{ color: "white", fontSize: 16 }} />
                </Box>
              }
              sx={{
                padding: 0,
              }} />
            <Typography className="agreement-text">I have the consent of the patient
            </Typography>

          </Box>
          <Box sx={{width:'600px',height:'110px',display:'flex',justifyContent:'space-between',flexDirection:'column',marginTop:'32px',marginBottom:'65px'}}>
            <Button className={this.state.acceptAggrementCheckbox&&this.state.patientConcent?'continue-Btn':'continue-Btn-disabled'} fullWidth
            disabled={!(this.state.acceptAggrementCheckbox&&this.state.patientConcent)}
           data-test-id="finalbtn"
            onClick={()=>
              this.handleSubmit()
            }>Continue</Button>
            <Box sx={{textAlign:'center',width:'600px'}}>

                <Typography className="already-acc">Already have an account? Log in</Typography>  
            </Box>
          </Box>

          <Button sx={{bottom:'150px'}} data-test-id="backbtn" className="back-btn" onClick={()=>this.setState({continue:false})}>Back</Button>

        </>:
        <Formik data-test-id='formik'
        initialValues={{ firstName: "", lastName: "", mobileNumber: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
        const data={...values,email:this.state.email,contactMethod: this.state.contactMethod,
          RepresentativeCause:this.state.RepresentativeCause, Title:this.state.Title,
          islandine:this.state.landline,landineNumber:this.state.landlineNumber
        }
      this.handleRepresentativeAPI(data)
  
        }}
      >
        {({ errors, touched, handleChange, handleBlur, values }) => (
          <Form>

        
          <Box className='nameContainer'>
                    <Box className='name'>
                      <Typography className="nameText">First Name <span className="mandotary">*</span></Typography>
                        <Field placeholder="Tanner" fullWidth as={TextField}
                        name="firstName"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}/>
                    </Box>
                    <Box className='name'>
                    <Typography className="nameText">First Name <span className="mandotary">*</span></Typography>
                    <Field placeholder="clarkson" fullWidth as={TextField}
                    name="lastName"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}/>
                    </Box>
          </Box>
          <Box className='mobileContainer' >
            <Typography className="nameText">Mobile Number <span className="mandotary">*</span></Typography>
             <Field as={TextField}
             name="mobileNumber"
             variant="outlined"
             fullWidth
             disabled={this.state.landline}
             onChange={handleChange}
             onBlur={handleBlur}
             disable
             value={values.mobileNumber}
             error={touched.mobileNumber && Boolean(errors.mobileNumber)}
             helperText={touched.mobileNumber && errors.mobileNumber}/>
             <Box className='checkbox_or_landline'>
             <Checkbox  data-test-id='landline' onClick={()=>this.setState({landline:!this.state.landline})} icon={
      <Box
                className="checkbox" />
            }
              checkedIcon={
                <Box className="checkedIcon"
                >
                  <CheckIcon sx={{ color: "white", fontSize: 16 }} />
                </Box>
              }
              sx={{
                padding: 0,
              }} />
              <Typography className="landline" style={{marginBottom:'-12px'}}>I don't have a mobile - add a landline</Typography>
             </Box>

          </Box>
             <Box sx={{width:'600px',height:'44px',display:`${this.state.landline?'block':'none'}`,marginTop:'30px'}}>
              <TextField data-test-id="landlinefield" fullWidth placeholder="+1 (123) 456-7890" onChange={(e)=>this.setState({landlineNumber:e.target.value})} sx={{marginTop:'50px'}}/>
             </Box>
             <Box className='btnContainer'>
                  <Button fullWidth className="continue-Btn" type="submit">Continue</Button>
                  <Box sx={{textAlign:'center',width:'600px'}}>

<Typography className="already-acc">Already have an account? Log in</Typography>  
</Box>
             </Box>
             <Button className="back-btn" data-test-id='btnback' onClick={()=>this.setState({continue:false})}>Back</Button>
          
          </Form>)}
        </Formik>
        
      }
     </Box>   
<RegistrationFooter/>
        </Box>

      </StyledWrapper>
      // Customizable Area End
    )
  }
}

// Customizable Area Start

const StyledWrapper = styled(Box)({
  "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
  "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
  "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
  "& .mainContainer": {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFAF6'
  },
  "& .stepper": {
    width: '600px',
    marginTop: '32px',
  },
  "& .headingContainer": {
    width: '700px', marginTop: '50px'
  },
  "& .mainHeading": {
    fontFamily: 'Raleway',
    fontWeight: 600,
    fontSize: '41px',
    color: '#2C2524'
  },
  "& .subHeadingContainer": {
    marginTop: '32px',
    width: '600px',
    textAlign: 'center'
  },
  "& .subHeading": {
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#2C2524'
  },
  "& .mobileNumberContainer": {
    width: '600px',
    marginTop: '32px'
  },
  "& .mobileNumber": {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    color: '#2C2524'
  },
  "& .mandotary": {
    color: '#DC2626', fontWeight: 700
  },
  "&  .MuiOutlinedInput-root": {
    height: '44px',
    borderRadius: '24px',
    borderColor: '#E6C79C',

  },
  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: '#E6C79C !important'
  },
  "& .non-mandotary": { fontSize: '16px', color: '#78716C', fontWeight: 400 },
  "& .checkBox-container": {
    width: '290px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  "& .prefered-contacts": {
    marginTop: '24px',
    width: '600px'
  },
  "& .checkbox": {
    width: 20,
    height: 20,
    border: "2px solid gray",
    borderRadius: "6px",
  },
  "& .checkedIcon": {
    width: 20,
    height: 20,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: '#1A4A42'
  },
  "& .agreement-box": {
    display: 'flex',
    gap: '8px',
    width: '615px',
    alignItems: 'center',
    paddingLeft: '100px'
  },
  "& .agreement-text": {
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#2C2524'
  },
  "& .underline-text": {
    fontStyle: 'italic',
    color: '#1A4A42',
    textDecoration: 'underline'
  },
  "& .continue-Btn-disabled":{
    borderRadius:'24px',
    height:'56px',
    backgroundColor:'#E7E5E4',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#A8A29E',
    fontSize:'20px',
    textTransform:'capitalize',
    "&:hover":{
      backgroundColor:'#E7E5E4',
    }
  },
  "& .already-acc":{
    fontFamily:'Raleway',
    fontSize:'20px',
    fontWeight:700,
    color:'#E46760',
    textDecoration:'underline'
  },

"& .continue-Btn":{
    borderRadius:'24px',
    height:'56px',
    backgroundColor:'#E46760',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'white',
    fontSize:'20px',
    textTransform:'capitalize',
    "&:hover":{
      backgroundColor:'#E46760',
    },

  },
  "& .nameContainer":{
    marginTop:'32px',
    width:'600px',
    height:'72px',
    display:'flex',
    justifyContent:'space-between'
  },
  "& .name":{
    width:'288px',
    height:'110px',
    display:'flex',
    flexDirection:'column'
  },
  "& .nameText":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontWeight:700,
    color:'#2C2524'
  },
  "& .mobileContainer":{width:'600px',height:'72px',display:'flex',justifyContent:'space-between',flexDirection:'column',marginTop:'24px'}
,"& .checkbox_or_landline":{
  width:'340px',
  display:'flex',
  gap:'8px',
  marginTop:'8px'

},
"& .landline":{
  fontFamily:'Poppins',
  fontSize:'16px',
  color:'#2C2524'
},
"& .btnContainer":{width:'600px',height:'110px',marginTop:'65px',
  display:'flex',flexDirection:'column',justifyContent:'space-between',
  marginBottom:'130px'
},
"& .back-btn":{
  width:'51px',
  height:'23px',
  textTransform:'capitalize',
  color:'#78716C',
  fontWeight:700,
  position:'relative',
  bottom:'230px',
  right:'500px',
  fontSize:'20px'
}

})
// Customizable Area End