import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button,TextField} from "@material-ui/core";
import Loader from '../../../../../src/packages/components/src/Loader.web';

// Customizable Area End
import OrderSummaryController, {
    Props,
  } from "./OrderSummaryController.web";

  export default class OrderSummary extends OrderSummaryController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      return (
        // Customizable Area Start
        <>
        {this.state.isLoading?
        <Loader loading={true}/>:
        <StyledWrapper>
            <Box className="mainContainer">
            <Typography className="mainHeading">Order Summary</Typography>
            <Box className="orderSummaryContainer">
                <Box sx={{width:'600px',height:'52px',display:'flex',justifyContent:'space-between'}}>
             <Box sx={{width:'282px'}}>
                <Typography className="referral">Do you have a Referral Code?</Typography>
                <Typography className="referralSubheading">If so, enter it here.</Typography>
             </Box>
                 <Box sx={{width:'287px'}}>

                 <TextField fullWidth placeholder="_ _ _ _ _ _ _ _" variant="outlined"/>
                 </Box>
                </Box>
                <hr style={{width:'600px',border:'1px solid #2C2524'}}/>
                <Box className="orderDetails">
                 <Box className="orderPlan">
                <Typography className="plan"><span style={{textTransform:'capitalize'}}>

                    {this.state.memberShipType+' '} 
                </span>
                     Wellness Membership Plan  /mo.</Typography>
                 <Typography className="price">${this.state.price}</Typography>
                 </Box>
                 <hr style={{width:'600px',border:'1px solid #D6D3D1'}}/>
                 <Box className="orderPlan">
                <Typography className="price">Taxes</Typography>
                <Typography className="price">$9.50</Typography>
                 </Box>
                 <Box className="orderPlan">
                <Typography className="price">Subtotal</Typography>
                <Typography className="price">${parseFloat(`${parseFloat(this.state.price)+9.50}`).toFixed(2)}</Typography>
                 </Box>
                </Box>
                <hr style={{width:'600px',border:'1px solid #2C2524'}}/>
                <Box className="totalBox">
                <Typography className="total">Total:</Typography>
                <Typography className="total">${parseFloat(`${parseFloat(this.state.price)+9.50}`).toFixed(2)}</Typography>
                </Box>

            </Box>
            <Box className="continueBtnContainer" data-test-id="box">
                <Box sx={{width:'184px',height:'56px',
                    display:'flex',justifyContent:'center',alignItems:'center'
                }}>
                <Button className="backBtn" data-test-id="navigation" onClick={()=>this.handleNavigate('MembershipLevel')}>Back</Button>

                </Box>
              <Button className="continueBtn" data-test-id="navigation" onClick={()=>this.handleNavigate('Checkout')}>Continue</Button>
            </Box>
            </Box>
        </StyledWrapper>}
        </>
        // Customizable Area End
      )
    }
  }
   
// Customizable Area Start
const StyledWrapper=styled(Box)({
"& .mainContainer":{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    backgroundColor:'#fff',
},
"& .mainHeading":{
    fontFamily:'Poppins',
    fontSize:'35px',
    fontWeight:500,
    color:'#2C2524',
    marginTop:'124px'
},
"& .orderSummaryContainer":{
    width:'600px',
    height:'274px',
    display:'flex',
    justifyContent:'space-between',
    flexDirection:'column',
    marginTop:'40px'
},
"& .referral":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'16px',
    color:'#2C2524'
},
"& .referralSubheading":{
    fontFamily:'Poppins',
    fontSize:'13px',
    color:'#2C2524'
},
"&  .MuiOutlinedInput-root": {
        height: '52px',
        borderRadius: '24px',
        borderColor: '#76928E',
        borderWidth:'2px'

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#76928E !important'
    },
"& .orderDetails":{
    width:'600px',
    height:'96px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between'
},
"& .totalBox":{
    width:'600px',
    height:'30px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
},
"& .orderPlan":{
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
},
"& .plan":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'16px',
    color:'#2C2524'
},
"& .price":{
    fontFamily:'Poppins',
    fontWeight:400,
    fontSize:'16px',
    color:'#2C2524'
},
"& .total":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'20px',
    color:'#2C2524' 
},
"& .continueBtnContainer":{
    width:'600px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:'320px',
    marginBottom:'120px'
},
"& .continueBtn":{
    width:'392px',
    height:'56px',
    borderRadius:'24px',
    textTransform:'capitalize',
    backgroundColor:'#1A4A42',
    color:'#fff',
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',

},
"& .backBtn":{
    width:'51px',
    height:'23px',
    textTransform:'capitalize',
    color:'#78716C',
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
}
})
// Customizable Area End
