Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "splitpayments2";
exports.labelBodyText = "splitpayments2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.finalizeYourBooking = "Finalize your Booking";
exports.homeAddress = "Home Address";
exports.detailsTxt = "Details:";
exports.homeAddressOpenDesc = "If you are a snowbird or have two addresses please indicate that within your account settings once you log in";
exports.paymentDetailsTxt = "Payment Details";
exports.whyWeAskCreditTxt = "Why we ask for your Credit Card Info?";
exports.learnMoreTxt = "Learn More";
exports.cardDetailsTxt = "Credit / Debit Card Details";
exports.cardNumberLabel = "Card Number";
exports.nameOnCardTxt = "Name on Card";
exports.expirationDateTxt = "Expiration Date";
exports.securityCodeTxt = "Security Code";
exports.cvvTxt = "(CVV)";
exports.billingInformationTxt = "Billing Information";
exports.useSameInformationTxt = "Use the same information as Home Address";
exports.stateTxt = "State";
exports.cityNameTxt = "City";
exports.zipCodeTxt = "Zip Code";
exports.saveDetailsTxt = "Save Details";
exports.orderSummaryTxt = "Order Summary";
exports.reviewOrderTxt = "Please review your order below and";
exports.pressSubmitTxt = "press submit to confirm";
exports.providerTxt = "Provider:";
exports.meredithGreyTxt = "Meredith Grey";
exports.durationTxt = "Duration:";
exports.sixtyMinutesTxt = "60 minutes";
exports.startTimeTxt = "Start Time:";
exports.oneThirtyPmCstTxt = "Wednesday, Aug 15, 2024";
exports.dateTxt = "Date:";
exports.wednesdayAugust15th2024Txt = "Wednesday, August 15th, 2024";
exports.subtotalTxt = "Subtotal";
exports.oneHundredFiftyDollarsTxt = "$150.00";
exports.taxesTxt = "Taxes";
exports.thirtyDollarsThirtyFiveCentsTxt = "$30.35";
exports.totalTxt = "Total:";
exports.oneHundredEightyDollarsThirtyFiveCentsTxt = "$180.35";
exports.couponCodeTxt = "Do you have a Coupon Code?";
exports.enterCouponTxt = "If so, enter it here.";
exports.acceptTxt = "Accept";
exports.noShowPolicyTxt = "no show policy";
exports.areYouStillHereTitle = "Are you still there?";
exports.ifYouHaveQuestionTxt = "If you have questions feel free to call us at";
exports.questionHelpNumber = "1-800-567-5433";
exports.ImStillHereTxt = "I’m still here";
exports.thereWasErrorFirstLineTxt = "There was an error";
exports.thereWasErrorSecondLineTxt = "processing your payment.";
exports.tryAgainTxt = "Try Again";
exports.errorModalDesc1 = "If you continue to have problems,"
exports.pleaseTxt = "please";
exports.errorModalDesc2 = "contact us for assistance"
exports.errorModalDesc3 = "Or call 1-800-567-LIFE"
// Customizable Area End