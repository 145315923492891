export const MembershipOptions=[
    {
        membership:'Bronze Membership',
        membershipContent:[
            'Access to the platform',
            'Lorem ipsum dolor'
        ],
        price:'$20.00 /month',
        marginTop:"105px",
        bestPlan:'bronze'
    },
    {
        membership:'Silver Membership',
        membershipContent:[
            'Access to the platform',
            'Lorem ipsum dolor',
            'Amet consectetur'
        ],
        price:'$35.00 /month',
         marginTop:"65px",
          bestPlan:'silver'
    },
    {
        membership:'Gold Membership',
        membershipContent:[
            '5 dedicated Providers',
            'Access to the platform',
            'Lorem ipsum dolor',
            'Amet consectetur'
        ],
        price:'$49.99 /month',
        marginTop:'27px',
         bestPlan:'gold'
    },

]

export const membershipShipDetails:any={
    "bronze":{
        topHeading:'Bronze Wellness Membership',
        bottomHeading:'Lorem ipsum dolor sit amet consectetur. Dictumst nibh senectus libero dolor non facilisis enim. Turpis nibh.',
        enjoyBenefitHeading:'Enjoy the Benefits:',
        enjoyBenifitOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Aliquam elit pharetra sed sagittis bibendum Rutrum tristique at risus pellentesque.',
            'Vitae mattis sodales est nunc aliquam massa fermentum',
        ],

        alsoGetHeading:'Also get:',
        alsoGetOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Vitae mattis sodales est nunc aliquam',
            'Rutrum tristique at risus pellentesque.'
        ],
        price:'$20.00 /month',
        btnText:'Sign Up to Bronze Membership',

    },
    "silver":{
        topHeading:'Silver Wellness Membership',
        bottomHeading:'Lorem ipsum dolor sit amet consectetur. Dictumst nibh senectus libero dolor non facilisis enim. Turpis nibh.',
        enjoyBenefitHeading:'Enjoy the Benefits:',
        enjoyBenifitOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Aliquam elit pharetra sed sagittis bibendum Rutrum tristique at risus pellentesque.',
            'Vitae mattis sodales est nunc aliquam massa fermentum',
        ],

        alsoGetHeading:'Also get:',
        alsoGetOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Vitae mattis sodales est nunc aliquam',
            'Rutrum tristique at risus pellentesque.'
        ],
        price:'$35.00 /month',
        btnText:'Sign Up to Silver Membership',

    },
    "gold":{
        topHeading:'Gold Wellness Membership',
        bottomHeading:'Lorem ipsum dolor sit amet consectetur. Dictumst nibh senectus libero dolor non facilisis enim. Turpis nibh.',
        enjoyBenefitHeading:'Enjoy the Benefits:',
        enjoyBenifitOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Aliquam elit pharetra sed sagittis bibendum Rutrum tristique at risus pellentesque.',
            'Vitae mattis sodales est nunc aliquam massa fermentum',
        ],

        alsoGetHeading:'Also get:',
        alsoGetOption:[
            'Lorem ipsum dolor sit amet consectetur.',
            'Vitae mattis sodales est nunc aliquam',
            'Rutrum tristique at risus pellentesque.'
        ],
        price:'$49.99 /month',
        btnText:'Sign Up to Gold Membership',

    }

}