import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button } from "@material-ui/core";
import RegistrationFooter from "./components/RegistrationFooter.web";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {membershipShipDetails} from './MockData'
import Loader from '../../../../../src/packages/components/src/Loader.web';

// Customizable Area End
import BronzeMemberShipController, {
    Props,
} from "./BronzeMemberShipController.web";

export default class BronzeMemberShip extends BronzeMemberShipController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
            {this.state.isLoading?
            <Loader loading={true}/>:
            <StyledWrapper>
                <section className='SideBar'></section>
                <Box className="mainContainer">
                  <Typography className="mainHeading">{membershipShipDetails[this.state.membershipType].topHeading}</Typography>
                <Typography className="subHeading">{membershipShipDetails[this.state.membershipType].bottomHeading}</Typography>
                <Box className="membershipDetailContainer">
                   <Typography className="benefitHeading">{membershipShipDetails[this.state.membershipType].enjoyBenefitHeading}</Typography>
                {membershipShipDetails[this.state.membershipType].enjoyBenifitOption.map((item:string)=>(
                      <Box className="benefitContainer">
                        <CheckCircleOutlineIcon sx={{width:'20px',height:'20px',
                            color:'#1A4A42'
                        }}/>
                        <Typography className="benefitContent">{item}</Typography>
                        </Box>
                ))}
                <Typography className="benefitHeading" style={{marginTop:'24px'}}>
                    {membershipShipDetails[this.state.membershipType].alsoGetHeading}</Typography>
                    {membershipShipDetails[this.state.membershipType].alsoGetOption.map((item:string)=>(
                      <Box className="benefitContainer">
                        <CheckCircleOutlineIcon sx={{width:'20px',height:'20px',
                            color:'#1A4A42'
                        }}/>
                        <Typography className="benefitContent">{item}</Typography>
                        </Box>
                ))}
                <Typography className="price">{membershipShipDetails[this.state.membershipType].price}</Typography>
                </Box>
                <Button className="membershipBtn" onClick={()=>this.handleNavigation('OrderSummary')}
                    data-test-id='membershipBtn'>
                   {membershipShipDetails[this.state.membershipType].btnText}
                </Button>
                <Button className="viewBtn">
                View more plans
                </Button>
                <Button className="backBtn">Back</Button>
                </Box>
                <RegistrationFooter/>
            </StyledWrapper>}
            </>
            // Customizable Area End
        )
    }
}
 // Customizable Area Start
 const StyledWrapper=styled(Box)({
    "& .SideBar": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
    "& .mainContainer":{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:"center"
    },
    "& .mainHeading":{
        fontFamily:'Raleway',
        fontSize:'50px',
        fontWeight:700,
        color:'#1C1917',
        marginTop:'80px'

    },
    "& .subHeading":{
        width:'584px',
        fontFamily:'Poppins',
        fontSize:'20px',
        color:'#1C1917',
        marginTop:'20px'
    },
    "& .membershipDetailContainer":{
        width:'539px',
        height:'491px',
        marginTop:'58px'
    },
    "& .benefitHeading":{
        marginBottom:'24px',
        fontFamily:'Poppins',
        fontSize:'29px',
        fontWeight:500,
        color:'#1C1917'
    },
    "& .benefitContainer":{
        width:'100%',
        display:'flex',
        gap:'10px'
    },
    "& .benefitContent":{
        fontFamily:'Poppins',
        fontSize:'20px',
        fontWeight:400,
        color:'#1C1917'
    },
    "& .price":{
        fontFamily:'Poppins',
        fontSize:'29px',
        fontWeight:700,
        color:'#1C1917',
        marginTop:"24px"
    },
    "& .membershipBtn":{
        width:'600px',
        height:'56px',
        marginTop:'50px',
        borderRadius:'24px',
        backgroundColor:'#E46760',
        color:'#fff',
        textTransform:'initial',
        fontFamily:'Raleway',
        fontWeight:700,
        fontSize:'20px'

    },
    "& .viewBtn":{
        width:'185px',
        height:'23px',
        textTransform:'initial',
        color:'#78716C',
        fontFamily:'Raleway',
        fontSize:'20px',
        fontWeight:700,
        marginTop:'32px',
        marginBottom:'140px'
    },
    "& .backBtn":{
        width:'51px',
        height:'23px',
        textTransform:'capitalize',
        color:'#78716C',
        fontFamily:'Raleway',
        fontSize:'20px',
        fontWeight:700,
        position:'relative',
        right:400,
        bottom:230

    }
 })
// Customizable Area End
