import React from "react"
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ActiveAfternoon, ActiveMorning, ActiveNight, Afternoon, Morning, Night } from "../assets";
import { Moment } from "moment";
// Customizable Area Start
export const configJSON = require("../config");

interface Provider {
  id: number,
  name: string;
  profileImg: string;
  fullProfileText: string;
  qualification: string;
  specialties: string[];
  initialSessionPrice: number;
  sessionDuration: string;
  nextAvailability: string;
  selectProviderText: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepperStep: string[],
  isLoading: boolean,
  patient_id: number,
  timeOfDay: { time: string, image: string, activeImage: string }[],
  providerList: Provider[],
  selectedId: number,
  selectedDayTime: string,
  chooseTimeList: string[],
  selectedTime: string,
  selectedDate: Moment | null,
  dateString: string,
  sortByAnchor: null | HTMLElement;
  sortByMenuItems: string[];
  sortByInitial: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChooseProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pageScrollTopRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pageScrollTopRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepperStep: ["About You", "Verify Insurance", "Choose Provider"],
      isLoading: false,
      patient_id: 158,
      timeOfDay: [
        { time: configJSON.morningTxt, image: Morning, activeImage: ActiveMorning },
        { time: configJSON.afternoonTxt, image: Afternoon, activeImage: ActiveAfternoon },
        { time: configJSON.nightTxt, image: Night, activeImage: ActiveNight },
      ],
      providerList: [
        {
          id: 1,
          name: "Lucy Liu",
          profileImg: "https://picsum.photos/200/300",
          fullProfileText: "Full Profile",
          qualification: "LMFT",
          specialties: ["Couple Therapy", "Anxiety"],
          initialSessionPrice: 50,
          sessionDuration: "60 mins",
          nextAvailability: "Aug 15",
          selectProviderText: configJSON.selectProviderTxt,
        },
        {
          id: 2,
          name: "John Doe",
          profileImg: "https://picsum.photos/200/301",
          fullProfileText: "Full Profile",
          qualification: "PhD",
          specialties: ["Depression", "Relaxation"],
          initialSessionPrice: 70,
          sessionDuration: "50 mins",
          nextAvailability: "Aug 20",
          selectProviderText: configJSON.selectProviderTxt
        },
        {
          id: 3,
          name: "John Doe",
          profileImg: "https://picsum.photos/200/301",
          fullProfileText: "Full Profile",
          qualification: "PhD",
          specialties: ["Depression", "Relaxation"],
          initialSessionPrice: 70,
          sessionDuration: "50 mins",
          nextAvailability: "Aug 20",
          selectProviderText: configJSON.selectProviderTxt
        },
        {
          id: 4,
          name: "Lucy Liu",
          profileImg: "https://picsum.photos/200/300",
          fullProfileText: "Full Profile",
          qualification: "LMFT",
          specialties: ["Couple Therapy", "Anxiety"],
          initialSessionPrice: 50,
          sessionDuration: "60 mins",
          nextAvailability: "Aug 15",
          selectProviderText: configJSON.selectProviderTxt,
        },
      ],
      selectedId: 0,
      selectedDayTime: "",
      chooseTimeList: ["7 - 8 AM", "8 - 9 AM", "9 - 10 AM", "10 - 11 AM",],
      selectedTime: "",
      selectedDate: null,
      dateString: "",
      sortByAnchor: null,
      sortByMenuItems: ["Recommended", "Price", "Alphabetic (A-Z)", "Alphabetic (Z-A)", "Number of sessions (Asc)", "Number of sessions (Desc)",],
      sortByInitial: "Recommended",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handleScrollToTop();
  }
  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  handleBack = () => {
    this.handleNavigate("DesignCareTeam")
  };

  joinWaitList = () => {
    this.handleNavigate("WaitList");
  }

  selectProvider = (selected_id: number) => {
    this.setState({
      selectedId: selected_id,
    })
  }

  setDayTime = (dayTime: string) => {
    if (this.state.selectedId) {
      this.setState({
        selectedDayTime: dayTime,
      });
    }
  };

  setSelectedTime = (selected_time: string) => {
    this.setState({
      selectedTime: selected_time,
    })
  }

  handleDateChange = (date: Moment | null) => {
    this.setState({
      selectedDate: date,
      dateString: date ? date.format("dddd, MMMM D") : "",
    })
  };

  handleOpenSortBy = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      sortByAnchor: event.currentTarget,
    })
  };

  handleCloseSortBy = () => {
    this.setState({
      sortByAnchor: null,
    })
  };

  handleSetSortBy = (text: string) => {
    this.setState({ sortByInitial: text, sortByAnchor: null, })
  }

  isValidForSession = () => {
    const { selectedId, selectedDayTime, selectedTime, selectedDate } = this.state;
    return selectedId && selectedDayTime && selectedTime && selectedDate;
  }

  handleCancel = () => {
    this.setState({
      selectedId: 0,
      selectedDayTime: "",
      selectedTime: "",
      selectedDate: null,
    }, () => {
      this.handleScrollToTop();
    });
  };

  handleConfirmSession = () => {
    this.handleNavigate("CoverageDecision");
  }

  handleScrollToTop = () => {
    if (this.pageScrollTopRef.current) {
      this.pageScrollTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  // css
  getProviderStyle = (providerId: number) => {
    return {
      border: providerId === this.state.selectedId ? "2px solid #E46760" : "2px solid #D6D3D1",
    };
  };

  getProviderButtonClass = (providerId: number) => {
    return providerId === this.state.selectedId ? "selectedProvderBtn" : "selectProvderBtn";
  };

  getProviderButtonText = (providerId: number) => {
    return providerId === this.state.selectedId ? configJSON.selectedProviderTxt : configJSON.selectProviderTxt;
  };

  getTextColor = () => {
    return this.state.selectedId ? "#2C2524" : "#A8A29E";
  };

  getTimeOfDayBoxBg = () => {
    return this.state.selectedDayTime !== "" ? "#F5F5F4" : "";
  };

  getTimeOfDayStyles = (time: string) => {
    const { selectedDayTime } = this.state;

    return {
      border: selectedDayTime === ""
        ? "1px solid #F5F5F4"
        : selectedDayTime === time
          ? "1px solid #F2B3B0"
          : "1px solid transparent",

      backgroundColor: selectedDayTime === ""
        ? "#F5F5F4"
        : selectedDayTime === time
          ? "#FAE1DF"
          : "#F5F5F4"
    };
  };

  getTimeOfDayImage = (time: { time: string; activeImage: string; image: string }) => {
    return this.state.selectedDayTime === time.time ? time.activeImage : time.image;
  };

  getTimeOfDayTextStyle = (time: { time: string }) => {
    return {
      opacity: !this.state.selectedId ? "40%" : "100%",
      color: this.state.selectedDayTime === time.time ? "#E46760" : "#78716C",
    };
  };

  getChooseTimeButtonStyle = (time: string) => {
    return {
      backgroundColor: this.state.selectedTime === time ? "#E46760" : "#FFFFFF",
      color: this.state.selectedTime === time ? "#FFFFFF" : "#E46760",
      fontWeight: this.state.selectedTime === time ? 700 : 400,
    };
  };

  // Customizable Area End
}
