import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  stepsList: string[],
  heading: string,
  subHeading: string,
  optionsList: { id: number, options_text: string }[],
  selectedOptions: number[],
  submiitedList: { id: number, option_text: string }[],
  isLoading: boolean,
  isHovered: boolean,
  patient_id: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DiagnoseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDiagnoseListApiCallId: string = "";
  getSubmittedDiagnoseListApiCallId: string = "";
  submitDiagnoseListApiCallId: string = "";
  skipStepApiCallId: string = "";
  pageTopRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pageTopRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stepsList: ["About You", "Verify Insurance", "Choose Provider"],
      heading: "",
      subHeading: "",
      optionsList: [],
      selectedOptions: [],
      submiitedList: [],
      isLoading: false,
      isHovered: false,
      patient_id: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId) {
        if (apiRequestCallId === this.getDiagnoseListApiCallId) {
          this.receiveGetDiagnoseList(responseJson);
        } else if (apiRequestCallId === this.submitDiagnoseListApiCallId) {
          this.receiveSubmitData(responseJson);
        } else if (apiRequestCallId === this.getSubmittedDiagnoseListApiCallId) {
          this.receiveGetSubmittedListData(responseJson);
        } else if (apiRequestCallId === this.skipStepApiCallId) {
          this.receiveSkipStep(responseJson)
        }
      }
    }
  }

  receiveGetDiagnoseList = (responseJson: { data: [{ attributes: { title: string, body: string, options: { id: number, options_text: string }[] } }] }) => {
    if (responseJson && responseJson.data) {
      let data = responseJson.data;
      let attributes = data && data[0] && data[0].attributes;
      this.setState({
        heading: attributes.title,
        subHeading: attributes.body,
        optionsList: attributes.options,
        isLoading: false,
      })
    }
  }

  receiveSubmitData = (responseJson: { message: string }) => {
    if (responseJson && responseJson.message === "Health review options updated successfully") {
      const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
      requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), "DiagnosedSelected");
      requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(requestMessage)

      if (this.pageTopRef.current) {
        this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  receiveGetSubmittedListData = (responseJson: { selected_options: { id: number, option_text: string }[] }) => {
    if (responseJson && responseJson.selected_options) {
      let data = responseJson.selected_options;
      this.setState((prevState) => ({
        submiitedList: data,
        isLoading: false,
        selectedOptions: [
          ...prevState.selectedOptions,
          ...data.map((item: { id: number }) => item.id),
        ],
      }));
    }
  }

  receiveSkipStep = (data: any) => {
    if (data && data.message === "Health review options updated successfully") {
      this.handleNavigate("SupportDesign")
    }
  }

  async componentDidMount() {
    this.getDiagnoseList();

    const patientId = await getStorageData('representativeid')
    this.setState({patient_id: patientId}, () => {
      this.getSubmittedDiagnoseList();
    })
  }

  handleNavigate = (path: string) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);
  }

  getDiagnoseList() {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiagnoseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiagnoseListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubmittedDiagnoseList() {
    this.setState({ isLoading: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubmittedDiagnoseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubmittedDiagnoseListEndpoint}/${this.state.patient_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitDiagnoseList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const data = {
      "patient_id": this.state.patient_id,
      "option_ids": this.state.selectedOptions,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitDiagnoseListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitDianoseListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let updatedOptions = [...prevState.selectedOptions];

      if (checked) {
        updatedOptions.push(Number(value));
      } else {
        updatedOptions = updatedOptions.filter(id => id !== Number(value));
      }

      return { selectedOptions: updatedOptions };
    });
  };

  handleContinue = () => {
    if (this.state.selectedOptions.length > 0 && !window.location.pathname.includes("Selected")) {
      this.submitDiagnoseList();
    } else if (window.location.pathname.includes("Selected")) {
      this.handleNavigate("SupportDesign")
    }
  }

  handleBack = () => {
    let isSelectedPage = window.location.pathname.includes("Selected");

    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(getName(MessageEnum.NavigationTargetMessage), isSelectedPage ? "Diagnose" : "MatchMakingMultiSelect");
    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    requestMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(requestMessage);

    if (this.pageTopRef.current) {
      this.pageTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  handleContinueBtnColor = () => {
    return (window.location.pathname.includes("Selected") || this.state.selectedOptions.length > 0) ? "#FFFAF6" : "#78716C"
  }

  handleBgCondition = () => {
    return this.state.selectedOptions.length > 0 || window.location.pathname.includes("Selected")
  }

  handleContinueBtnBgColor = () => {
    const { isHovered } = this.state;
    if (isHovered) {
      return "#B6524D";
    } else if (this.handleBgCondition()) {
      return "#E46760";
    } else {
      return "#D6D3D1";
    }
  }

  handleSkip = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const data = {
      "patient_id": this.state.patient_id,
      "option_ids": [],
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.skipStepApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitDianoseListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
