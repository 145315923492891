// Customizable Area Start
import React from "react";
import { Box, Checkbox, FormControlLabel, Step, StepLabel, Stepper, styled, Typography } from "@mui/material";
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";
import DiagnoseController, { Props, configJSON } from "./DiagnoseController.web";
import { skipIcon } from "../../customform/src/assets";
import Loader from '../../../../../src/packages/components/src/Loader.web';
import CheckIcon from "@mui/icons-material/Check";
import CareTeamHeader from "../../customform/src/components/CareTeamHeader";
import CustomStepper from "../../customform/src/components/CustomStepper.web";
import RegistrationHeadings from "../../customform/src/components/RegistrationHeadings.web";

// Customizable Area End

export default class Diagnose extends DiagnoseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box ref={this.pageTopRef}>
        <SidebarLine />
        {
          this.state.isLoading ? (
            <Loader loading={true} />
          ) : (
            <>
              <CustomWrapper>
                <CareTeamHeader />
                <CustomStepper
                  activeStep={0}
                  color="#FFFFFF"
                  bgColor="#E46760"
                  stepperSteps={this.state.stepsList}
                />
                
                <RegistrationHeadings
                  width="70%"
                  heading={this.state.heading}
                  subHeading={this.state.subHeading}
                  chooseFollowing={configJSON.chooseFollowingHeading}
                  chooseFollowingSubHeading={configJSON.selectAllSubHeading}
                />

                {
                  window.location.pathname.includes("Selected")
                    ? <Box className="selectedList">
                      {this.state.submiitedList && this.state.submiitedList.map((option) => {
                        return (
                          <>
                            <Typography key={option.id} className="selectedItem">{option.option_text}</Typography>
                          </>
                        )
                      })}
                    </Box>
                    : <Box className="listBox">
                      {
                        this.state.optionsList && this.state.optionsList.map((option) => {
                          return (
                            <FormControlLabel
                              key={option.id}
                              className="formControl"
                              data-test-id="formControlLabel"
                              control={
                                <Checkbox
                                  data-test-id="checkboxChange"
                                  value={option.id}
                                  checked={this.state.selectedOptions.includes(option.id)}
                                  onChange={this.handleCheckboxChange}
                                  style={{ marginRight: "8px" }}
                                  sx={{ padding: 0 }}
                                  icon={
                                    <span
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "6px",
                                        border: "1px solid #78716C",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    />
                                  }
                                  checkedIcon={
                                    <span
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "6px",
                                        border: "1px solid #1A4A42",
                                        backgroundColor: "#1A4A42",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CheckIcon style={{ color: "white", fontSize: "16px" }} />
                                    </span>
                                  }
                                />
                              }
                              label={<Typography className="checkboxLabel">{option.options_text}</Typography>}
                            />
                          )
                        })
                      }
                    </Box>
                }

                <Box className="actionBtnsBox">
                  <Typography className="backText" data-test-id="backBtn" onClick={this.handleBack}>{configJSON.backText}</Typography>
                  <Typography className={`continueText`} style={{
                    color: this.handleContinueBtnColor(),
                    backgroundColor: this.handleContinueBtnBgColor(),
                  }}
                    data-test-id="continueBtn"
                    onClick={this.handleContinue}
                    onMouseEnter={() => this.setState({ isHovered: this.handleBgCondition() })}
                    onMouseLeave={() => this.setState({ isHovered: false })}
                  >
                    {configJSON.continueText}
                  </Typography>
                  {!window.location.pathname.includes("Selected") && <Typography className="skipThisStepText" onClick={this.handleSkip} data-test-id="btnSkipThisStep">{configJSON.skipThisStepText} <img src={skipIcon} alt="" /></Typography>}
                </Box>

                <RegistrationFooter />
              </CustomWrapper>
            </>
          )
        }
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomWrapper = styled(Box)({
  marginLeft: "32px",
  boxSizing: "border-box",
  backgroundColor: "#FFFAF6",
  "& .subHeading": {
    margin: "32px auto 0 auto",
  },
  "& .listBox": {
    width: "fit-content",
    margin: "auto",
    marginTop: "40px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "8px 30px",
    "@media (max-device-width: 1084px)": {
      gap: "8px 10px",
    },
    "@media (max-device-width: 1041px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "8px 30px",
    },
    "@media (max-device-width: 720px)": {
      gridTemplateColumns: "repeat(1, 1fr)",
      gap: "10px 0",
    },
  },
  "& .formControl": {
    width: "fit-content",
  },
  "& .checkboxLabel": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A"
  },
  "& .selectedList": {
    width: "805px",
    margin: "auto",
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    "@media (max-device-width: 1041px)": {
      width: "650px",
      margin: "20px auto 0 auto",
    },
    "@media (max-device-width: 720px)": {
      width: "550px",
      fontSize: "20px",
    },
  },
  "& .selectedItem": {
    width: "fit-content",
    borderRadius: "26px",
    backgroundColor: "#E7E5E4",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#334155",
    padding: "4px 12px 4px 12px",
  },

  "& .stepper": {
    width: "600px",
    paddingTop: "32px",
    margin: "auto",
  },
  "& .backText": {
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: "20px",
    cursor: "pointer",
    color: "#78716C",
    lineHeight: "23.48px",
    fontFamily: "Raleway",
    order: "0",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
    },
  },
  "& .actionBtnsBox": {
    width: "80%",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "64px auto 88px auto",
    "@media (max-device-width: 980px)": {
      width: "100%",
      padding: "0 15px 0 15px",
    },
    "@media (max-device-width: 840px)": {
      margin: "48px auto 68px auto",
    },
    "@media (max-device-width: 716px)": {
      display: "grid",
      width: "fit-content",
      gridTemplateRows: "auto auto auto",
      gap: 20,
    },
    "@media (max-device-width: 490px)": {
      margin: "30px auto 40px auto",
    },
  },
  "& .skipThisStepText": {
    lineHeight: "23.48px",
    fontFamily: "Raleway",
    display: "flex",
    fontWeight: 700,
    letterSpacing: "1px",
    cursor: "pointer",
    alignItems: "center",
    color: "#807C7C",
    fontSize: "20px",
    order: "1",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
    },
  },
  "& .skipThisStepText img": {
    marginLeft: "8px",
  },
  "& .continueText": {
    fontFamily: "Raleway",
    fontWeight: "700",
    order: "-1",
    cursor: "pointer",
    letterSpacing: "1px",
    padding: "16.5px 149px",
    position: "absolute",
    borderRadius: "24px",
    lineHeight: "23.48px",
    left: "50%",
    fontSize: "20px",
    transform: "translateX(-50%)",
    "@media (max-device-width: 840px)": {
      fontSize: "18px",
      padding: "10px 120px",
    },
    "@media (max-device-width: 716px)": {
      margin: "auto",
      position: "static",
      transform: "none",
      left: "unset",
    },
  },
});


const SidebarLine = styled(Box)({
  width: "32px",
  height: "100vh",
  position: "fixed",
  backgroundColor: "#1A4A42",
});
// Customizable Area End
