import React from "react";


// Customizable Area Start

import { Box, Typography, Button, styled, TextField, InputAdornment } from "@material-ui/core";
import CustomStepper from "../../customform/src/components/CustomStepper.web"
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardArrowDown } from "@mui/icons-material";
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";
import Loader from '../../../../../src/packages/components/src/Loader.web';

// Customizable Area End

import SupplementInsuranceController, {
    Props,
} from "./SupplementInsuranceController.web";

export default class SupplementInsurance extends SupplementInsuranceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            
            <>
            {this.state.isLoading?
            (<Loader loading={true} />):
            (<StyledWrapper>
                <section className='leftBorder'></section>
                <Box className="background">
                    <CustomStepper
                        stepperSteps={this.state.stepperStep}
                        bgColor="#E46760"
                        color="#FFFFFF"
                        activeStep={1}
                    />
                    <Box className="mainContainer">
                        <Typography className="headerHeading">Supplemental Insurance</Typography>
                        <Box className="headerSubHeadingContainer">
                            <Typography className="headerSubheading">This information will be used to verify your coverage. Please ensure details are accurate.</Typography>
                        </Box>
                        <Box className="InputFieldContainer">
                            <Box className="textfieldContainer">
                                <Typography className="textfieldHeading">Secondary / Supplemental plan provider</Typography>
                                <TextField fullWidth variant="outlined" placeholder="Placeholder"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <KeyboardArrowDown color="disabled" />
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="disabled" />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Box>
                            <Box className="textfieldContainer">
                                <Typography className="textfieldHeading">Secondary / Supplemental plan provider</Typography>
                                <TextField fullWidth variant="outlined" placeholder="Placeholder" />
                            </Box>


                        </Box>
                        <Button className="coverageBtn">Check my coverage</Button>

                    </Box>
                </Box>
                <RegistrationFooter />
            </StyledWrapper>)}
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    "&": {
        backgroundColor: '#FFFAF6'
    },
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
    "& .mainContainer": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '60px',
        marginBottom: '200px'

    },
    "& .background": {
        backgroundColor: '#FFFAF6'
    },
    "& .headerHeading": {
        fontFamily: 'Raleway',
        fontSize: '40px',
        fontWeight: 600,
        color: '#2C2524'
    },
    "& .headerSubHeadingContainer": {
        width: '605px',
        marginTop: '20px',
        textAlign: 'center'
    },
    "& .headerSubheading": {
        fontFamily: 'Poppins',
        fontSize: '20px',
        color: '#2C2524'
    },
    "&  .MuiOutlinedInput-root": {
        height: '44px',
        borderRadius: '24px',
        borderColor: '#E6C79C',

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#E6C79C !important'
    },
    "& .InputFieldContainer": {
        width: '600px',
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '32px 20px 32px 20px',
        marginTop: '40px',
        border: '1px solid #A8A29E',
        borderRadius: '24px'
    },
    "& .textfieldContainer": {
        width: '600px',
        height: '72px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .textfieldHeading": {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 700,
        color: '#2C2524'
    },
    "& .coverageBtn": {
        width: '600px',
        height: '56px',
        backgroundColor: '#E46760',
        borderRadius: '24px',
        textTransform: 'initial',
        fontFamily: 'Raleway',
        fontSize: '20px',
        color: "#fff",
        fontWeight: 700,
        marginTop: '60px',
    }
})
// Customizable Area End
