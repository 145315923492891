import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button,TextField} from "@material-ui/core";
import {User,Plus} from './assets'

// Customizable Area End
import WelcomePageController, {
    Props,
  } from "./WelcomePageController.web";

  export default class WelcomePage extends WelcomePageController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      return (
        // Customizable Area Start
        <StyledWrapper>
            <Box className="Container">
                <Box className="headingContainer">
                 <Typography className="heading">
                 Welcome to our
                 <span style={{color:'#1A4A42'}}>{' '}Wellness Membership</span>
                 </Typography>
                </Box>
                <Box className="subheadingContainer">
                 <Typography className="subheading">Lorem ipsum dolor sit amet consectetur. Mus sit netus in sagittis id odio ac et.</Typography>
                </Box>
                <Box  className="profileContainer">
<img src={User} style={{marginTop:'43.5px',marginLeft:'36px'}}/>
<img src={Plus} style={{position:'relative',right:'20px',top:'15px'}}/>
   </Box>
   <Button className="loginBtn" onClick={()=>this.handleNavigation('EmailAccountLoginBlock')} data-test-id='loginBtn'>Login to the Total Life platform</Button>
            </Box>
        </StyledWrapper>
        // Customizable Area End
      )
    }
  }
// Customizable Area Start
const StyledWrapper=styled(Box)({
"& .Container":{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
},
"& .headingContainer":{
    width:'444px',
    height:'160px',
    textAlign:'center',
    marginTop:'120px'
},
"& .heading":{
     fontSize:'41px',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#2C2524'
},
"& .subheadingContainer":{
    width:'440px',
    height:'60px',
    textAlign:'center',
    marginTop:'45px'
},
"& .subheading":{
    fontFamily:'Poppins',
    fontWeight:400,
    fontSize:'20px',
    color:'#2C2524'
},
"& .profileContainer":{width:'200px',height:'200px',borderRadius:'50%',border:'4px solid #76928E',marginTop:'56px'},
"& .loginBtn":{
    width:'392px',
    height:'56px',
    textTransform:'initial',
    backgroundColor:'#E46760',
    color:'#fff',
    borderRadius:'24px',
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
    marginTop:'180px',
    marginBottom:'160px'
}
})
// Customizable Area End