import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button, TextField ,InputAdornment,Checkbox} from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Visa,americanExpress,Discover,applePay,mastro,masterCard} from './assets'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';

// Customizable Area End
import CheckoutController, {
    Props,
  } from "./CheckoutController.web";

  export default class  Checkout extends CheckoutController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End
  
    render() {
      return (
        // Customizable Area Start
        <StyledWrapper>
         
            <Box className="mainContainer">
                <Typography className="checkoutHeading">Checkout</Typography>
                <Box className="checkoutTypeContainer" data-test-id="stateClick" onClick={()=>this.setState({billDetailsShow:!this.state.billDetailsShow})}>
                  <Typography  className="checkoutType">Billing Information</Typography>
                  <Box onClick={()=>this.setState({billDetailsShow:!this.state.billDetailsShow})} data-test-id="stateClick">
                {this.state.billDetailsShow?
                  <KeyboardArrowUpIcon style={{width:'20px',height:'20px',color:'white'}}/>:
                  <KeyboardArrowDownIcon style={{width:'20px',height:'20px',color:'white'}}/>
                }
                  </Box>
                </Box>
                <Box className="checkoutForm" style={{display:this.state.billDetailsShow?'flex':'none'}}>
              <Box className="nameBox">
                <Typography className="boldText">First Name</Typography>
                <TextField fullWidth variant="outlined" placeholder="Neelam"/>
              </Box>
              <Box className="container">
              <Typography className="boldText">Last Name</Typography>
              <TextField fullWidth variant="outlined" placeholder="Brar"/>
              </Box>
              <Box className="container">
              <Typography className="boldText">Address</Typography>
              <TextField fullWidth variant="outlined" placeholder="742 Evergreen Terrace"/>
              </Box>
              <Box style={{display:'flex',justifyContent:'space-between',width:'600px'}}>
              <Box className="container" style={{width:'288px'}}>
              <Typography className="boldText">Apartment, Suite, etc. (Optional)</Typography>
              <TextField fullWidth variant="outlined" placeholder="Address Line 2"/>
              </Box>
              <Box className="container" style={{width:'288px'}}>
              <Typography className="boldText">City</Typography>
              <TextField fullWidth variant="outlined" placeholder="Springfield"/>
              </Box>
              </Box>
              <Box style={{display:'flex',justifyContent:'space-between',width:'600px'}}>
              <Box className="container" style={{width:'288px'}}>
              <Typography className="boldText">Country / Region</Typography>
              <TextField fullWidth variant="outlined" placeholder="United States"
              InputProps={{
                endAdornment:(
                    <InputAdornment position="end">
                    <KeyboardArrowDownIcon color="disabled"/>
                    </InputAdornment>
                )
              }}/>
              </Box>
              <Box className="container" style={{width:'288px'}}>
              <Typography className="boldText">Zip Code</Typography>
              <TextField fullWidth variant="outlined" placeholder="Enter your Zip Code"/>
              </Box>
              </Box>
                </Box>
                <Box className="checkoutTypeContainer" data-test-id="stateClick"
                onClick={()=>this.setState({showCreditDetails:!this.state.showCreditDetails})}>
                  <Typography  className="checkoutType">Credit / Debit Card Details</Typography>
                  {this.state.showCreditDetails?
                  <KeyboardArrowUpIcon style={{width:'20px',height:'20px',color:'white'}}/>:
                  <KeyboardArrowDownIcon style={{width:'20px',height:'20px',color:'white'}}/>}
                </Box>
                <Box className="checkoutForm" style={{height:'410px',
                  display:this.state.showCreditDetails?'flex':'none'
                }}>
                  <Box className="groupContainer">
                    <img src={Visa} alt='Visa'/>
                    <img src={masterCard} alt="masterCard"/>
                    <img src={applePay} alt="applePay"/>
                    <img src={americanExpress} alt="AmericalExpress"/>
                    <img src={mastro} alt="Mastro"/>
                    <img src={Discover} alt="Discover"/>
                  </Box>
                  <Box className="container">
                 <Typography className="boldText">Card Number</Typography>
                 <TextField fullWidth variant="outlined" placeholder="1234 1234 1234 1234"
                 InputProps={{
                  endAdornment:(
                    <InputAdornment position="end">
                      <LockOutlinedIcon color="disabled"/>
                    </InputAdornment>
                  )
                 }}/>
                  </Box>
                  <Box className="container">
                    <Typography className="boldText">Name on Card</Typography>
                    <TextField fullWidth variant="outlined" placeholder="Neelam Brar"/>
                  </Box>
                  <Box className="flexContainer">
                  <Box className="container" style={{width:'288px'}}>
                    <Typography className="boldText">Expiration Date</Typography>
                    <TextField fullWidth variant="outlined" placeholder="MM / YY"/>
                  </Box>
                  <Box className="container" style={{width:'288px'}}>
                    <Typography className="boldText">Name on Card</Typography>
                    <TextField fullWidth variant="outlined" placeholder="0 0 0"
                    InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <ErrorOutlineOutlinedIcon color="disabled"/>
                         </InputAdornment>
                      )
                    }}/>
                  </Box>
                  </Box>
                  <Box className="saveBox">
                  <Checkbox icon={
                            <Box
                             className="checkbox"/>}
                             checkedIcon={
                             <Box className="checkedIcon">
                            <CheckIcon sx={{ color: "white", fontSize: 16 }} />
                            </Box>} style={{padding: 0,}}/> 
                    <Typography className="saveText">Save this Credit Card for future payments</Typography>
                  </Box>
                 
              </Box>
              <Box className="btnContainer">
                <Button className="cancelBtn">Cancel</Button>
                <Button className="Paynow" onClick={()=>this.Navigate('WelcomePage')}
                  data-test-id="payBtn">Pay Now</Button>
              </Box>
               
            </Box>
        </StyledWrapper>
      )
    }
}

const StyledWrapper=styled(Box)({
"& .mainContainer":{
    width:'100%',
    display:'flex',
    alignItems:'center',
    flexDirection:'column'
},
"& .checkoutHeading":{
    fontFamily:'Poppins',
    fontSize:'35px',
    fontWeight:500,
    color:'#2C2524',
    marginTop:'124px',
   
},
"& .checkoutTypeContainer":{
    width:'592px',
    height:'48px',
    backgroundColor:'#1A4A42',
    borderRadius:'24px',
    position:'relative',
    "z-index":'10',
    marginTop:'50px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    paddingLeft:'24px',
    paddingRight:'24px'
},
"& .checkoutForm":{
    width:'640px',
    height:'480px',
    border:"1px solid #A8A29E",
    borderRadius:'10px',
    position:'relative',
    bottom:'30px',
    borderBottomLeftRadius:'24px',
    borderBottomRightRadius:'24px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',

},
"& .checkoutType":{
    fontFamily:'Poppins',
    fontWeight:700,
    fonytSize:'20px',
    color:'white'
},
"&  .MuiOutlinedInput-root": {
        height: '44px',
        borderRadius: '24px',
        borderColor: '#E6C79C',

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#E6C79C !important'
    },
"& .boldText":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontWeight:700
},
"& .nameBox":{display:'flex',flexDirection:'column',width:'600px',height:'72px',marginTop:'40px'},
"& .container":{
    display:'flex',
    flexDirection:'column',
    width:'600px',
    height:'72px',
    marginTop:'14px'
},
"& .groupContainer":{
  width:"600px",
  display:'flex',
  justifyContent:'flex-end',
  marginTop:'40px'
},
"& .flexContainer":{
  width:'600px',
  display:'flex',
  justifyContent:'space-between'
},
"& .checkbox":{
    width: 20,
    height: 20,
    border: "2px solid gray",
    borderRadius: "6px", 
  },
  "& .checkedIcon":{
    width: 20,
    height: 20,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
   backgroundColor:'#1A4A42'
  },
  "& .saveBox":{
    width:'600px',
    display:'flex',
    gap:'8px',
    marginTop:'27px'
  },
  "& .saveText":{
    fontFamily:'Poppins',
    fontSize:'16px',
    color:'#2C2524'
  },
  "& .btnContainer":{
    width:'600px',
    display:'flex',
    justifyContent:'space-around',
    alignItems:'center',
    marginTop:'60px',
  },
  "& .cancelBtn":{
    width:'184px',
    height:'56px',
    textTransform:'capitalize',
    fontFamily:'Raleway',
     fontSize:'20px',
     color:'#78716C',
     fontWeight:700
  },
  "& .Paynow":{
width:'392px',
height:'56px',
backgroundColor:'#1A4A42',
color:'#fff',
textTransform:'capitalize',
borderRadius:'24px',
fontFamily:'Raleway',
fontWeight:700,
fontSize:'20px'
  }
})