import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
   membershipType:string,
   isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BronzeMemberShipController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
    membershipType:'',
    isLoading:true
       // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);}
   // Customizable Area Start
async componentDidMount(): Promise<void> {
    const membership=await getStorageData('membershipPlan')
    this.setState({membershipType:membership})
    setTimeout(() => {
      this.setState({isLoading:false})
   }, 5000); 
}

   handleNavigation = (path: string) => {
    const NewMessage = new Message(getName(MessageEnum.NavigationMessage));
    NewMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    NewMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    NewMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(NewMessage);}
    // Customizable Area End
}