import React from "react";

// Customizable Area Start
import { Box, styled, Typography, Button, TextField, InputAdornment } from "@material-ui/core";
import { HeaderLogoImg } from '../../landingpage/src/assets';
import Radio from '@mui/material/Radio';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { KeyboardArrowDown ,KeyboardArrowRight } from "@mui/icons-material";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
// Customizable Area End

import Cfemploymentcontractconsole2Controller, {
  Props,
} from "./Cfemploymentcontractconsole2Controller";

export default class CredentialFormSection1 extends Cfemploymentcontractconsole2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Box>
          <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            <Box className='iconContainer'>
              <PersonOutlineIcon className="icon" />
            </Box></Box>
          <Box className="mainContainer">
            <Box className="formContainer">
              <Box className="headingContainer">
                <Box className="heading">
                  <Typography className="headingContent">Credentialing - General Information</Typography>
                  <Typography className="subHeading">Section 1.1</Typography>
                </Box>
                <Typography className="navigation">1 of 8</Typography>
              </Box>
              <Box sx={{paddingLeft:'42.5px',borderTop:'1px solid solid'}}>
                <Box className="seperator"></Box>
              </Box>
              <Box className="bodyContainer">
                <Typography className="bodyHeading">Please add your general information in the fields below.</Typography>
                <Box className="nameContainer">
                  <Box className="name">
                    <Typography className="nametext">First Name<span className="mandotary">*</span></Typography>
                    <TextField variant="outlined" placeholder="Enter your Fist Name" type="text" className="nameField" />
                  </Box>
                  <Box className="name">
                    <Typography className="nametext">Middle Name<span className="non-mandotary">(if applies)</span></Typography>
                    <TextField variant="outlined" placeholder="Enter your Middle Name" type="text" className="nameField" />
                  </Box>
                  <Box className="name">
                    <Typography className="nametext">Last Name<span className="mandotary">*</span></Typography>
                    <TextField variant="outlined" placeholder="Enter your Last Name" type="text" className="nameField" />
                  </Box>

                </Box>
                <Box className="formerNaneContainer">
                  <Typography className="nametext">Former/Maiden Name <span className="non-mandotary">(if applies)</span></Typography>
                  <TextField placeholder="Enter your Former/Maiden Name" className="maidenTextfield" variant="outlined" />
                </Box>
                <Box className="genderContainer">
                  <Box className="name">
                    <Typography className="nametext">Sex<span className="mandotary">*</span></Typography>
                    <TextField variant="outlined" placeholder="Please Select" type="text" className="nameField"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">

                            <KeyboardArrowDown color="disabled" />

                          </InputAdornment>
                        ),
                      }} />
                  </Box>
                  <Box className="name">
                    <Typography className="nametext">Gender<span className="mandotary">*</span></Typography>
                    <TextField variant="outlined" placeholder="Please Select" type="text" className="nameField"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">

                            <KeyboardArrowDown color="disabled" />

                          </InputAdornment>
                        ),
                      }} />
                  </Box>
                  <Box className="name">
                    <Typography className="nametext">What is your ethnicity?<span className="mandotary">*</span></Typography>
                    <TextField variant="outlined" placeholder="Please Select" type="text" className="nameField"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">

                            <KeyboardArrowDown color="disabled" />

                          </InputAdornment>
                        ),
                      }} />
                  </Box>
                </Box>
                <hr style={{ width: '936px', border: '1px solid #E7E5E4', marginTop: '24px' }} />
                <Box className="personalDetailContainer">
                  <Box className="detailBox">
                    <Typography className="nametext">Personal Email<span className="mandotary">*</span></Typography>
                    <TextField placeholder="neelam@totallife.com" className="detailtextfield" variant="outlined" type="email" />

                  </Box>
                  <Box className="detailBox" sx={{ marginRight: '20px' }}>
                    <Typography className="nametext">Contact Phone Number<span className="mandotary">*</span></Typography>
                    <TextField placeholder="000-000-0000" className="detailtextfield" variant="outlined" type="text" />

                  </Box>
                </Box>
                <hr style={{ width: '936px', border: '1px solid #E7E5E4', marginTop: '24px' }} />
                <Box className="addressContainer">

                  <Box className="homeAdress">
                    <Typography className="nametext">Home Address<span className="mandotary">*</span></Typography>
                    <TextField className="street" fullWidth variant="outlined" placeholder="Street Address"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <FmdGoodOutlinedIcon color="disabled" />
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start" >
                            <SearchOutlinedIcon color="disabled" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  <Box sx={{ width: '728px', marginTop: '16px' }} >
                    <TextField className="street" fullWidth variant="outlined" placeholder="Street Address line 2" />
                  </Box>
                  <Box className="box">
                    <Box className="city">
                      <Box className="subbox">
                        <Typography className="nametext">City<span className="mandotary">*</span></Typography>
                        <TextField fullWidth variant="outlined" placeholder="Enter your City"
                          className="cityfield" />
                      </Box>
                      <Box className="subbox">
                        <Typography className="nametext">State<span className="mandotary">*</span></Typography>
                        <TextField fullWidth variant="outlined" placeholder="Alabama"
                          className="cityfield"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlinedIcon color="disabled" />
                              </InputAdornment>
                            )
                          }} />
                      </Box>
                    </Box>
                    <Box className="city">
                      <Box className="subbox">
                        <Typography className="nametext">Zip Code<span className="mandotary">*</span></Typography>
                        <TextField fullWidth variant="outlined" placeholder="00000"
                          className="cityfield" />
                      </Box>
                      <Box className="subbox">
                        <Typography className="nametext">Time Zone<span className="mandotary">*</span></Typography>
                        <TextField fullWidth variant="outlined" placeholder="Please Select"
                          className="cityfield"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <KeyboardArrowDown color="disabled" />
                              </InputAdornment>
                            )
                          }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <hr style={{ width: '936px', border: '1px solid #E7E5E4', marginTop: '24px' }} />
                <Box className="DOBcontainer">
                  <Box className="DOB">
                    <Typography className="nametext">Date of Birth <span className="mandotary">*</span></Typography>
                    <TextField className="cityfield" fullWidth variant="outlined" placeholder="MM-DD-YYYY"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <InsertInvitationOutlinedIcon color="disabled" />
                          </InputAdornment>
                        )
                      }}

                    />

                  </Box>
                  <Box className="SOB">
                    <Typography className="nametext">State of Birth<span className="mandotary">*</span></Typography>
                    <TextField className="cityfield" fullWidth variant="outlined" placeholder="Alabama"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon color="disabled" />
                          </InputAdornment>
                        )
                      }}

                    />

                  </Box>
                </Box>
                <hr style={{ width: '936px', border: '1px solid #E7E5E4', marginTop: '24px' }} />
                <Box className="fileUpload">
                  <Box className="fileCard">
                    <Typography className="nametext">Professional Headshot<span className="mandotary">*</span></Typography>

                    <Box className="fileuploaderBox"
                      
                      
                    >
                      <input
                        type="file"
                        id="file-upload"
                        hidden
                        
                      />
                      <label htmlFor="file-upload">
                        <FileUploadOutlinedIcon sx={{
                          fontSize: 40, color: "#777", width: '64px',
                          height: '64px',
                        }} />
                        <Typography className="uploadtext">
                          {this.state.file?.name || "Click or Drop files to upload"}
                        </Typography>
                        <Button variant="text" component="span" className="uploadbtn">
                          Upload
                        </Button>
                      </label>
                    </Box>
                    <Typography className="fileuploadtext" >** Please use a photo with a plain white background</Typography>
                  </Box>
                  <Box className="fileCard">
                    <Typography className="nametext">Government Issued Photo <span className="mandotary">*</span></Typography>

                    <Box className="fileuploaderBox"
                     
                     
                    >
                      <input
                        type="file"
                        id="file-upload"
                        hidden
                       
                      />
                      <label htmlFor="file-upload">
                        <FileUploadOutlinedIcon sx={{
                          fontSize: 40, color: "#777", width: '64px',
                          height: '64px',
                        }} />
                        <Typography className="uploadtext">
                          {this.state.file?.name || "Click or Drop files to upload"}
                        </Typography>
                        <Button variant="text" component="span" className="uploadbtn">
                          Upload
                        </Button>
                      </label>
                    </Box>
                    <Typography className="fileuploadtext" >** Please provide a photo or scanned document</Typography>
                  </Box>
                  <Box className="fileCard">
                    <Typography className="nametext">Copy of your Resume<span className="mandotary">*</span></Typography>

                    <Box className="fileuploaderBox"
                      
                     
                    >
                      <input
                        type="file"
                        id="file-upload"
                        hidden
                      
                      />
                      <label htmlFor="file-upload">
                        <FileUploadOutlinedIcon sx={{
                          fontSize: 40, color: "#777", width: '64px',
                          height: '64px',
                        }} />
                        <Typography className="uploadtext">
                          {this.state.file?.name || "Click or Drop files to upload"}
                        </Typography>
                        <Button variant="text" component="span" className="uploadbtn">
                          Upload
                        </Button>
                      </label>
                    </Box>
                    <Typography className="fileuploadtext" >** Word or PDF Format</Typography>
                  </Box>

                </Box>
                <hr style={{ width: '936px', border: '1px solid #E7E5E4', marginTop: '100px' }} />
                <Box className="tableContainer">
                <Typography className="nametext">Work Experience Categories
                  <span className="non-mandotary">(select all that apply)</span><span className="mandotary">*</span></Typography>
                <Box className="table">
                  <Box className="table-heading">
                    <Box className="empty"></Box>
                    <Box className="col-heading">
                      <Typography className="column-heading">Basic Knowledge</Typography>
                      <Typography className="column-subheading">
                      <Checkbox
      icon={
        <Box
        className="checkbox"/>
      }
      checkedIcon={
        <Box className="checkedIcon"
        >
          <CheckIcon sx={{ color: "white", fontSize: 16 }} />
        </Box>
      }
      sx={{
        padding: 0,
      }}
    /> Select all
                      </Typography>
                    </Box>
                    <Box className="col-heading">
                      <Typography className="column-heading">On-the-job Experience</Typography>
                      <Typography className="column-subheading">
                      <Checkbox
      icon={
        <Box className="checkbox"
        />
      }
      checkedIcon={
        <Box className="checkedIcon"
        >
          <CheckIcon sx={{ color: "white", fontSize: 16 }} />
        </Box>
      }
      sx={{
        padding: 0,
      }}
    />  Select all
                      </Typography>
                    </Box>
                    <Box className="col-heading">
                      <Typography className="column-heading">Formal Training</Typography>
                      <Typography className="column-subheading">
                      <Checkbox
      icon={
        <Box className="checkbox"
        />
      }
      checkedIcon={
        <Box className="checkedIcon"
        >
          <CheckIcon sx={{ color: "white", fontSize: 16 }} />
        </Box>
      }
      sx={{
        padding: 0,
      }}
    /> Select all
                      </Typography>
                    </Box>
                    <Box className="col-heading">
                      <Typography className="column-heading">Will not Accept</Typography>
                      <Typography className="column-subheading">
                      <Checkbox
      icon={
        <Box className="checkbox"
        />
      }
      checkedIcon={
        <Box className="checkedIcon"
        >
          <CheckIcon sx={{ color: "white", fontSize: 16 }} />
        </Box>
      }
      sx={{
        padding: 0,
      }}
    />  Select all
                      </Typography>
                    </Box>
                  </Box>
                  {
                    this.state.tablelist.map((item,index)=>(

                  <Box className={index%2==0?'table-data-container':'table-data-container-gray'} key={index}
                  
                  >
                  <Box className="label">
                    <Typography className="label-data">{item}</Typography>
                  </Box>
                  <Box className="radio-box">
                  <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
               <Box className="radio-box" >
               <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                  <Box className="radio-box" >
                  <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                  <Box className="radio-box">
                  <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                
                  </Box>
                    ))
                  }
                  <Box className='table-data-container-gray-last'
                  >
                  <Box className="label">
                    <Typography className="label-data">Private Practice</Typography>
                  </Box>
                  <Box className="radio-box">
                        <Radio
                                  icon={
                                    <Box className="radio-outline"/>
                                  }
                                  checkedIcon={
                                    <Box className="radio-border" >
                                      <Box className="radio-background"/>
                                    </Box>
                                  }
                                  sx={{
                                    padding: 0, 
                                  }}
                                />
                  </Box>
               <Box className="radio-box" >
                                        <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                  <Box className="radio-box" >
                  <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                  <Box className="radio-box">
                  <Radio
                                      icon={
                                        <Box className="radio-outline"/>
                                      }
                                      checkedIcon={
                                        <Box className="radio-border" >
                                          <Box className="radio-background"/>
                                        </Box>
                                      }
                                      sx={{
                                        padding: 0, 
                                      }}
                                    />
                  </Box>
                
                  </Box>
                </Box>

                </Box>
              </Box>
             
            </Box>
            <Box className="save-next-container">
                <Typography className="save-next">Save and Exit</Typography>
                <Button endIcon={<KeyboardArrowRight/>} className="save-btn">Next</Button>

              </Box>
              
          </Box>
        </Box>
        <Box className="bottomFooter" >
                  <Typography className="footerContent">Need Help?</Typography>
                  <Button endIcon={<PhoneCallbackIcon color="disabled"/>} className="callBtn">Schedule a Call</Button>
               </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .checkbox":{
    width: 20,
    height: 20,
    border: "2px solid gray",
    borderRadius: "6px", 
  },
  "& .checkedIcon":{
    width: 20,
    height: 20,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
   backgroundColor:'#1A4A42'
  },
    "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
  "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
  "& .iconContainer": { height: '44px', width: '44px', borderRadius: '50%', border: '1px solid #D1DBD9', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '20px' },
  "& .icon": { color: '#76928E', height: '24px', width: '24px' },
  "& .mainContainer": { width: '100%', display: 'flex', justifyContent: 'center',flexDirection:'column',alignItems:'center' },
  "& .formContainer": { marginTop: '40px', width: '1016px', height: '2756px', border: '1px solid #D6D3D1', borderTopRightRadius: '24px', backgroundColor: '#fff' ,borderTopLeftRadius:'24px'},
  "& .headingContainer": {
    height: '116px', 
    paddingLeft: '40px', paddingRight: '24px', display: 'flex', justifyContent: 'space-between',
    alignItems: 'center',
  },
  "& .heading": {
    width: '854px',
    height: '68px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '2px',
    color: '#2C2524'

  },
  "& .headingContent": {
    fontWeight: 700,
    fontSize: '24px',

  },
  "& .subHeading": {
    fontSize: '20px',
    color: '#78716C'
  },
  "& .navigation": {
    fontSize: '20px',
    fontColor: '#44403C',
    width: '88px'
  },
  "& .bodyContainer": {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  "& .bodyHeading": {
    width: '936px',
    fontSize: '16px',
    color: '#807C7C',
    marginTop: '30px'
  },
  "& .nameContainer": {
    width: '936px',
    height: '84px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px'
  },
  "& .name": {
    display: 'flex',
    flexDirection: 'column',
    width: '304px'
  },
  "& .nameField": {
    width: '280px',
    height: '44px',
    borderColor: '#E6C79C',
    borderRadius: '24px',
    '& .MuiOutlinedInput-root': {
      width: '280px',
      height: '44px',
      borderColor: '#E6C79C',
      borderRadius: '24px',
      '& fieldset': {
        width: '280px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&:hover fieldset': {
        width: '280px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&.Mui-focused fieldset': {
        width: '280px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
    }
  },
  "& .nametext": {
    fontWeight: 700,
    fontSize: '16px',
    color: '#2C2524',
    fontFamily: 'Poppins'
  },
  "& .mandotary": { color: 'red', fontWeight: 400 },
  "& .non-mandotary": { fontSize: '16px', color: '#78716C', fontWeight: 400 },
  "& .formerNaneContainer": {
    width: '936px',
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '24px'
  },
  "& .maidenTextfield": {
    width: '99%',
    height: '44px',
    borderColor: '#E6C79C',
    borderRadius: '24px',
    '& .MuiOutlinedInput-root': {
      width: '99%',
      height: '44px',
      borderColor: '#E6C79C',
      borderRadius: '24px',
      '& fieldset': {
        width: '99%',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&:hover fieldset': {
        width: '99%',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&.Mui-focused fieldset': {
        width: '99%',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
    }
  },
  "& .genderContainer": {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    width: '936px'
  },
  "& .personalDetailContainer": {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '936px',
    height: '84px'
  },
  "& .detailBox": {
    width: '440px',
    display: 'flex',
    flexDirection: 'column',
  },
  "& .detailtextfield": {
    width: '440px',
    height: '44px',
    borderColor: '#E6C79C',
    borderRadius: '24px',
    '& .MuiOutlinedInput-root': {
      width: '440px',
      height: '44px',
      borderColor: '#E6C79C',
      borderRadius: '24px',
      '& fieldset': {
        width: '440px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&:hover fieldset': {
        width: '440px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&.Mui-focused fieldset': {
        width: '440px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
    }
  },
  "& .addressContainer": {
    marginTop: '24px',
    height: '320px',
    width: '936px',
    display: 'flex',
    flexDirection: 'column'
  },
  "& .homeAdress": {
    width: '728px',
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
  },
  "& .street": {
    width: '728px',
    height: '44px',
    borderColor: '#E6C79C',
    borderRadius: '24px',
    '& .MuiOutlinedInput-root': {
      width: '728px',
      height: '44px',
      borderColor: '#E6C79C',
      borderRadius: '24px',
      '& fieldset': {
        width: '728px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&:hover fieldset': {
        width: '728px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&.Mui-focused fieldset': {
        width: '728px',
        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
    }
  },
  "& .box": {
    width: '728px',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '146px'

  },
  "& .cityfield": {

    height: '44px',
    borderColor: '#E6C79C',
    borderRadius: '24px',
    '& .MuiOutlinedInput-root': {

      height: '44px',
      borderColor: '#E6C79C',
      borderRadius: '24px',
      '& fieldset': {

        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&:hover fieldset': {

        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
      '&.Mui-focused fieldset': {

        height: '44px',
        borderColor: '#E6C79C',
        borderRadius: '24px',
      },
    }
  },
  "& .city": {
    width: '748px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .subbox": {
    width: '356px'
  },
  "& .DOBcontainer": {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '936px',
    height: '84px'
  },
  "& .DOB": {
    width: '564px',
    height: '72px',
    display: 'flex',
    flexDirection: 'column'
  },
  "& .SOB": {
    width: '356px',
    height: '72px',
    display: 'flex',
    flexDirection: 'column'

  },
  "& .fileUpload": {
    width: '936px',
    height: '452px',
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    marginTop: '24px'
  },
  "& .fileuploaderBox": {
    border: "2px dashed #ccc",
    borderRadius: "24px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "#f1f1f1",
    "&:hover": { backgroundColor: "#f1f1f1" },
    width: '356px',
    height: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  "& .uploadtext": {
    fontFamily: 'Poppins', fontSize: '16px', color: '#A8A29E'
  },
  "& .uploadbtn": {
    display: 'none'
  },
  "& .fileuploadtext": {
    fontSize: '13px',
    color: '#807C7C',
    fontFamily: 'Poppins'

  },
  "& .fileCard": { display: 'flex', flexDirection: 'column' },
  "& .tableContainer":{marginTop:'24px',display:'flex',flexDirection:'column'},
  "& .table":{
    marginTop:'12px',
    width:'936px',
    height:'867px',
    border:'1px solid #D6D3D1', 
    borderRadius: '24px'
  },
  "& .table-heading":{
    width:'100%',
    height:'82px',
    backgroundColor:'#E7E5E4',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    borderTopRightRadius:'24px',
    borderTopLeftRadius:'24px'
  },

  "& .empty":{
    width:'236px',
    height:'24px'
  },
  "& .col-heading":{
      display:'flex',
      width:'154px',
      height:'50px',
      flexDirection:'column',
      justifyContent:'center'
  },
  "& .column-heading":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'12px',
    color:'#2C2524'
  },
  "& .column-subheading":{
    fontFamily:'Poppins',
    fontSize:'13px',
    color:'#2C2524',
    marginTop:'10px'
  },
"& .table-data-container":{
  height:'56px',
  width:'936px',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center',
  backgroundColor:'#fff'

},
"& .label":{
  width:'220px',
},
"& .label-data":{
  fontSize:'16px',
  fontFamily:'Poppins',
  color:'#2C2524'
},
"& .radio-box":{
  width:'154px',
  display:'flex',
  justifyContent:'center'
},
"& .table-data-container-gray":{
  height:'56px',
  width:'936px',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center',
  backgroundColor:'#F5F5F4'

},
"& .table-data-container-gray-last":{
  height:'56px',
  width:'936px',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center',
  backgroundColor:'#F5F5F4',
  borderBottomRightRadius:'24px',
  borderBottomLeftRadius:'24px'

},
"& .save-next-container":{
width:'938px',
height:'104px',
paddingLeft:'40px',
paddingRight:'40px',
display:'flex',
justifyContent:'space-between',
border:'1px solid #E7E5E4',
borderTop:'none',
borderBottomLeftRadius:'24px',
borderBottomRightRadius:'24px',
alignItems:'center',
backgroundColor:'#fff',
marginBottom:'34px'
},
"& .save-next":{
  fontFamily:'Raleway',
  fontSize:'20px',
  fontWeight:700,
  color:'#78716C'
},
"& .save-btn":{
  width:'184px',
  height:'44px',
  borderRadius:'16px',
  padding:'10px 16px 10px 16px',
  color:'#fff',
  backgroundColor:'#E46760',
  textTransform:'capitalize',
  fontSize:'20px',
  fontWeight:700,
  fontFamily:'Raleway'
},
"& .bottomFooter":{
    height:'100px',
    width:'100%',
    display:'flex',
    justifyContent:'flex-end',
    backgroundColor:'#fff',
    alignItems:'center',
    gap:'16px'
},
"& .footerContent":{
    fontSize:'16px',
    fontWeight:700,
    fontFamily:'Poppins',
    color:'#44403C'
},
"& .callBtn":{
  border:'2px solid #E46760',
  borderRadius: '16px',
  color: '#E46760',
textTransform:'initial',
marginRight:"144px",
width:'230px',
height:'44px',
fontFamily:'Raleway',
fontWeight:700,
fontSize:'20px'
},
"& .callBtn:hover":{
  border:'2px solid #E46760',
},
"& .footerContainer":{
  display:'flex',
  alignItems:'center'
},
"& .seperator":{width:'480px',height:'6px',backgroundColor:'#1A4A42'},
"& .radio-outline":{
  width: 20,
            height: 20,
            border: "1px solid #D6D3D1",
            borderRadius: "50%",
},
"& .radio-border":{
  width: 20,
            height: 20,
            border: "1px solid #D6D3D1", 
            borderRadius: "50%",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
},
"& .radio-background":{
  width: 10,
              height: 10,
              backgroundColor: "black",
              borderRadius: "50%",
}
})
// Customizable Area End