import React from "react";



// Customizable Area Start
import { Box, styled, Typography, Button } from "@material-ui/core";
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import RegistrationFooter from "./components/RegistrationFooter.web";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {MembershipOptions} from './MockData'
// Customizable Area End
import MembershipLevelController, {
    Props,
} from "./MembershipLevelController.web";

export default class MembershipLevel extends MembershipLevelController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <StyledWrapper>
                <section className='SideBar'></section>

                <Box className="topHeader" style={{ borderBottom: "1px solid #E7E5E4" }}>
                    <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                </Box>
                <Box className="mainContainer">
                    <Typography className="mainHeading">Choose your Membership Level</Typography>
                    <Box className="subHeadingContainer">
                    <Typography className="subHeading">
                    Nam libero tempore, cum soluta nobis est eligendi 
                    optio cumque nihil impedit quo minus 
                    id quod maxime placeat facere possimus, omnis voluptas assumenda est,
                    </Typography>
                    </Box>
                    <Box className="membershipCardContainer">
                        {MembershipOptions.map((item,index)=>(

                     <Box className="card"
                     style={{backgroundColor:this.state.selected===index?'#1A4A42':'#fff'}}>
                        {item.bestPlan==='silver'&&
                        <Box className="bestPlan" style={{backgroundColor:'#E46760'}}>
                            <Typography className="popular">Most Popular</Typography>
                        </Box>
                        }
                        {item.bestPlan==='gold'&&
                        <Box className="bestPlan" style={{backgroundColor:'#E6C79C'}}>
                            <Typography className="best">Best Value</Typography>
                        </Box>
                        }
                        {item.bestPlan==='bronze'&&
                        <Box className="bestPlan">
                            
                        </Box>
                        }
                        <Typography className="cardHeading" 
                        style={{color:this.state.selected===index?'#fff':'#1C1917'}}>{item.membership}</Typography>
                        <Typography className="cardSubHeading"
                        style={{color:this.state.selected===index?'#fff':'#2C2524'}}>Lorem ipsum dolor sit amet consectetur.</Typography>
                        {item.membershipContent.map(i=>(

                        <Box className="cardContentContainer">
                            <CheckCircleOutlineIcon sx={{width:'17px',height:'17px',color:this.state.selected===index?'#fff':'#1A4A42'}}/>
                            <Typography className="cardOptions" style={{color:this.state.selected===index?'#fff':'#2C2524'}}>{i}</Typography>
                        </Box>
                        ))}

                        <Typography className="price" style={{color:this.state.selected===index?'#fff':'#2C2524'}}>{item.price}</Typography>
                        <Button className="selectBtn" style={{marginTop:item.marginTop,
                            backgroundColor:this.state.selected===index?'#E46760':'#fff',
                            color:this.state.selected===index?'#fff':'#E46760'
                        }} onClick={()=>this.handleClick(index)} data-test-id="choosePlan">Choose Plan</Button>
                        <Box sx={{width:'282px',display:'flex',justifyContent:'center'}} style={{cursor:'pointer'}}
                        onClick={()=>(this.handleNavigate(index))} data-test-id="detailPlan">

                        <Typography className="details" style={{color:this.state.selected===index?'#fff':'#807C7C'}}>Click here for full details</Typography>
                        </Box>
                     </Box>
                        ))}
                    </Box>
                </Box>
               <RegistrationFooter/>
            </StyledWrapper>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    "& .SideBar": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
    "& .topHeader": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff", justifyContent: 'space-between' },
    "& .logoImg": { marginLeft: "100px", width: "162px", height: "41.24px" },
    "& .mainContainer": { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
        backgroundColor:'#E8EDEC'
    },
    "& .mainHeading":{
        fontFamily:'Raleway',
        fontSize:'50px',
        fontWeight:700,
        color:'#1C1917',
        marginTop:'50px'
    },
    "& .subHeadingContainer":{
        width:'601px',
        height:'90px',
        textAlign:'center',
        marginTop:'16px'
    },
    "& .subHeading":{
        fontFamily:'Poppins',
        fontSize:'20px',
        fontWEight:400,
        color:'#1C1917'
    },
    "& .membershipCardContainer":{
width:'1130px',
display:'flex',
justifyContent:'space-between',
marginTop:'60px',
marginBottom:'122px'
    },
"& .card":{
    width:'336px',
    height:'550px',
    border:'0.5px dashed  #203447',
    borderRadius:'20px',
    backgroundColor:'#fff',
    paddingLeft:'24px'
},
"& .cardHeading":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'24px',
    marginTop:'18px',
    color:'#1C1917',

},
"& .cardSubHeading":{
marginTop:'21px',
fontFamily:'Poppins',
fontSize:"16px",
fontWeight:400,
color:'#2C2524',
marginBottom:'29px',
width:'282px'
},
"& .cardContentContainer":{
    display:'flex',
    alignItems:'center',
    width:'213px',
    marginTop:'14px'
},
"& .cardOptions":{
    fontSize:'16px',
    fontFamily:'Poppims',
    color:'#2C2524',marginLeft:'10px'
},
"& .price":{
    marginTop:'40px',
    fontSize:'20px',
    fontFamily:'Poppins',
    fontWeight:700,
    color:'#2C2524'
},
"& .selectBtn":{
    width:'288px',
    height:'56px',
    borderRadius:"24px",
    border:'2px solid #E46760',
    color:'#E46760',
    backgroundColor:'#fff',
    fontFamily:'Inter',
    fontWeight:700,
    fontSize:'18px',
    textTransform:'capitalize'
},
"& .details":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontStyle:'italic',
    textDecoration:'underline',
    marginTop:'24px',
    color:'#807C7C'
},
"& .bestPlan":{
    marginTop:'16px',
    width:'144px',
    height:'28px',
    position:'relative',
    right:'25px',
    borderTopRightRadius:'6px',
    borderBottomRightRadius:'6px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around'
},

"& .popular":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'16px',
    color:'#fff'
},
"& .best":{
    fontFamily:'Poppins',
    fontWeight:700,
    fontSize:'16px',
    color:'#2C2524'
}




})
// Customizable Area End