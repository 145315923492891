import React from "react";


// Customizable Area Start

import { Box,Typography,Button,styled,TextField,InputAdornment} from "@material-ui/core";
import CustomStepper from "../../customform/src/components/CustomStepper.web"
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardArrowDown } from "@mui/icons-material";
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";
import Loader from "../../../../../src/packages/components/src/Loader.web";
// Customizable Area End

import Cfpverifyintegration1Controller, {
  Props,
} from "./Cfpverifyintegration1Controller";
import InsurancePopUp from "./InsurancePopUp.web";

export default class Cfpverifyintegration1 extends Cfpverifyintegration1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.isLoading?(
        <Loader loading={true} />
      ):(
     <StyledWrapper>
 <section className='leftBorder'></section>
        <Box className="background">
        <CustomStepper
                        stepperSteps={this.state.stepperStep}
                        bgColor="#E46760"
                        color="#FFFFFF"
                        activeStep={1}
                      />
        <Box className="mainContainer">
        <Typography className="mainHeading">Primary Insurance</Typography>
        <Box className="subHeadingContainer">
            <Typography className="subHeading">This information will be used to verify your coverage. Please ensure details are accurate.</Typography>
        </Box>
        <Box className="formContainer">
             <Box className="formFieldContainer" sx={{width:"100%"}}>
              <Typography className="textfieldHeading">Secondary / Supplemental plan provider</Typography>
             <TextField fullWidth placeholder="Placeholder" variant="outlined"
             InputProps={{
              startAdornment:(
                    <InputAdornment position="start">
                      <SearchIcon color="disabled"/>
                    </InputAdornment>
              ),
              endAdornment:(
                <InputAdornment position="end">
                  <KeyboardArrowDown color="disabled"/>
                </InputAdornment>
              )
             }}/>
             </Box>
             <Box className="formFieldContainer" sx={{width:"100%"}}>
              <Typography className="textfieldHeading">Member ID</Typography>
             <TextField fullWidth placeholder="1 E G 4 - T E 5 - M K 7 2" variant="outlined"/>
             </Box>
             <Box className="formFieldContainer" sx={{width:"290px"}}>
              <Typography className="textfieldHeading">Zip Code</Typography>
             <TextField fullWidth variant="outlined"/>
             </Box>
        </Box>
        {this.state.openPopUp&&<InsurancePopUp id="" navigation='' openState={this.state.openPopUp}
        handleClose={this.handleClose}/>}
        <Button className="continueBtn" onClick={()=>this.setState({openPopUp:true})} data-test-id='continueBtn'>Continue</Button>
        <Typography className="footerText">I don't have my insurance details on me</Typography>
        </Box>
        <RegistrationFooter/>
        </Box>
     </StyledWrapper>)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper=styled(Box)({
"&":{
 backgroundColor:'#FFFAF6'
},
  "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 99999 },
  "& .mainContainer":{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    marginTop:'60px',
    marginBottom:'60px'

  },
  "& .background":{
    backgroundColor:'#FFFAF6'
  },
  "& .mainHeading":{
    fontFamily:'Raleway',
    fontSize:'41px',
    fontWeight:600,
    color:'#2C2524'
  },
  "& .subHeadingContainer":{
    width:'605px',
    textAlign:"center",
    marginTop:'20px'
  },
  "& .subHeading":{
    fontWeight:400,
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#2C2524'
  },
  "& .formContainer":{
    width:'640px',
    height:'250px',
    backgroundColor:'#FFFFFF',
    border:'1px solid #A8A29E',
    borderRadius:'24px',
    marginTop:'40px',
    display:'flex',
    padding:'32px 20px 32px 20px',
    flexDirection:'column',
    gap:'16px'
  },
"&  .MuiOutlinedInput-root": {
        height: '44px',
        borderRadius: '24px',
        borderColor: '#E6C79C',

    },
    "& .MuiOutlinedInput-notchedOutline ": {
        borderColor: '#E6C79C !important'
    },
  "& .formFieldContainer":{
    display:'flex',
    flexDirection:'column',

  },
  "& .textfieldHeading":{
    fontFamily:'POppins',
    fontSize:"16px",
    fontWeight:700
  },
  "& .continueBtn":{
    width:'600px',
    height:'56px',
    borderRadius:'24px',
    backgroundColor:'#E46760',
    marginTop:'60px',
    textTransform:'capitalize',
    fontWeight:700,
    fontFamily:'Raleway',
    fontSize:'20px',
    color:'#fff'
  },
  "& .footerText":{
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
    color:'#E46760',
    textDecoration:'underline',
    marginTop:'32px'
  }
})


// Customizable Area End
