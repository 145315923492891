import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../landingpage/src/assets';
import { Box,styled ,Typography,Button} from "@material-ui/core";
import {signupLandingpage} from './assets'
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";

// Customizable Area End

import SignupLandingPageController, {
    Props
  } from "./SignupLandingPageController.web";

  export default class SignupLandingPage extends SignupLandingPageController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    } 
    render() {
  
      return (
        // Customizable Area Start
        <StyledWrapper>
          <Box className="mainContainer">
          <section className='leftBorder'></section>
            <Box className="TopNav" style={{ borderBottom: "1px solid #E7E5E4" }}>
              <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
  
             </Box>
          <Box className="TopBoxContainer">
            <Box className="BannerContainer">
               <Box className="headingContainer">
                 <Typography className="heading">Feeling better is just a few clicks away</Typography>
               </Box>
               <Box className="subHeadingContainer">
                      <Typography className="subHeading">Therapy customized for you and covered by Medicare. To get started please select an option below</Typography>
               </Box>
               <Button className="Btn" data-test-id="patient"
               onClick={()=>{this.handleNavigation("patient")}}>Get Started</Button>
               <Box className="RepresentativeContainer">
                 <Typography className="patientRepresentative" data-test-id="representative" onClick={()=>{this.handleNavigation("representative")}}>I am a Patient Representative</Typography>
               </Box>
            </Box>
            </Box>
            <RegistrationFooter/>
          </Box>
            </StyledWrapper>
    // Customizable Area End
    )}}
// Customizable Area Start
  const StyledWrapper=styled(Box)({
    "& .mainContainer":{
height:'100vh',
display:'flex',
flexDirection:'column',
justifyContent:'space-between',
backgroundColor:'#fff'
    },
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed',  top: 0, left: 0, zIndex: 99999 },
    "& .TopNav": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff",justifyContent:'space-between'},
    "& .logoImg": { marginLeft: "40px", width: "162px", height: "41.24px" },
    "& .TopBoxContainer":{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        backgroundColor:'#fff'
    }, 
    "& .BannerContainer":{
       width:'100%',
       height:'670px',
       backgroundImage:`url(${signupLandingpage})`,
       backgroundSize:'cover',
       backgroundPosition:'center',
       backgroundRepeat:'no-repeat',
       display:'flex',
       flexDirection:'column',
       alignItems:'center',
       marginBottom:'140px'
    },
    "& .headingContainer":{
    width:'740px',
    height:'120px',
    textAlign:'center',
    marginTop:'272px'
    },
    "& .heading":{
        fontFamily:'Raleway',
        fontSize:'50px',
        fontWeight:700
    },
"& .subHeadingContainer":{
    width:'600px',
    height:'60px',
    textAlign:'center',
    marginTop:'40px'
},
"& .subHeading":{
    fontFamily:'Poppins',
    fontSize:'20px',
    fontWeight:400
},
"& .Btn":{
    width:'392px',
    height:'56px',
    backgroundColor:'#E46760',
    textTransform:'capitalize',
    borderRadius:'24px',
    fontFamily:'Raleway',
    fontWeight:700,
    color:'#fff',
    fontSize:'20px',
    marginTop:'40px'

},

"& .RepresentativeContainer":{
    width:'392px',
    textAlign:'center',
    marginTop:'40px',
},
"& .patientRepresentative":{
    fontFamily:'Raleway',
    fontWeight:700,
    fontSize:'20px',
    textDecoration:'underline',
    color:'#E46760',
    cursor:'pointer'
}
  


})
// Customizable Area End