import React from "react";


// Customizable Area Start

import { Box,Typography,Button,styled,Modal} from "@material-ui/core";
import { Group } from "./assets";

// Customizable Area End


import InsurancePopUpController,{Props} from "./InsurancePopUpController.web";

export default class InsurancePopUp extends InsurancePopUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Modal
      open={true}
      onClose={this.props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <StyledWrapper>
      <Box className="mainContainer">
        <Box sx={{width:'544px',textAlign:'center',marginTop:'40px'}}>
            <Typography className="modalHeading">Do you have a </Typography> 
                <Typography className="modalHeading">supplemental insurance?</Typography>

        </Box>
        <img src={Group} className="image" alt="groupimage"/>
        <Button className="acceptBtn" onClick={()=>{this.props.handleClose()
          this.handleNavigate('SupplementInsurance')}}>Yes</Button>
        <Button className="coverageBtn">No, check my coverage</Button>
      </Box>

        </StyledWrapper>
    </Modal>

     // Customizable Area End
    )
}
}
// Customizable Area Start
const StyledWrapper=styled(Box)({
"& .mainContainer":{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    backgroundColor: '#fff',
    boxShadow: "24",
    borderRadius:'24px',
    height:'554px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center', },
    "& .modalHeading":{
        fontWeight:500,
        fontSize:"30px",
        fontFamily:'Poppins'
    },
    "& .image":{
        marginTop:'44px'
    },
    "& .acceptBtn":{
        width:'390px',
        height:'56px',
        borderRadius:'24px',
        backgroundColor:'#E46760',
        marginTop:'60px',
        textTransform:'capitalize',
        fontWeight:700,
        fontFamily:'Raleway',
        fontSize:'20px',
        color:'#fff'
    },
    "& .coverageBtn":{
        width:'392px',
        height:'56px',
        border:'2px solid #E46760',
        textTransform:'capitalize',
        marginTop:'24px',
        backgroundColor:'#fff',
        color:'#E46760',
        borderRadius:'24px',
        fontFamily:'Raleway',
        fontWeight:700,
        fontSize:'20px',
        
    }
})
// Customizable Area End