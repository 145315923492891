import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../landingpage/src/assets';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import {
  Box,
  Button,
  styled,
  Typography,Step,Stepper,StepLabel,
  TextField,Checkbox,InputAdornment,MenuItem
} from '@mui/material';
import { Formik, Form,Field } from "formik";
import * as Yup from "yup";
import RegistrationFooter from "../../customform/src/components/RegistrationFooter.web";

const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Enter a valid 10-digit mobile number")
      .length(10, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
  });
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, 'First name must be at least 2 characters')
      .max(20, 'First name must be at most 20 characters')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Last name must be at least 2 characters')
      .max(20, 'Last name must be at most 20 characters')
      .required('Last name is required'),
    dateOfBirth: Yup.date()
      .required('Date of birth is required')
      .nullable()
      .typeError('Invalid date format')
   });
// Customizable Area End

import PatientRegistrationController, {
  Props
} from "./PatientRegistrationController.web";

export default class PatientSignupForm2 extends PatientRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  } 
  render() {

    return (
      // Customizable Area Start
      <StyledWrapper>
        <Box style={{height:'100vh',display:'flex',flexDirection:'column',justifyContent:'space-between',
            backgroundColor:'#FFFAF6'
        }}>

        <section className='leftBorder'></section>
          <Box className="TopHeader" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="LOGO" src={HeaderLogoImg} alt="HeaderLogoImg" />

           </Box>
        <Box className="mainBox">
            <Stepper alternativeLabel className="SignupTracker">
                        {this.state.steps.map((label, index) => (
                          <Step key={label}
                            sx={{
                              '& .MuiStepLabel-root .Mui-active': {
                                color: '#E46760',
                              },
                              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: '#FFFFFF',
                              },
                            }}>
                            <StepLabel>
                              <Typography sx={index == 0 ? { fontFamily: 'Poppins', fontSize: '16px', fontWeight: 700, color: '#2C2524' } : {}}>
            
                                {label}
                              </Typography>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
           {this.state.isPatient==='patient'? <Typography className="mainHeading"> Your Wellness Journey Starts Here </Typography>:
            <Typography className="mainHeading"> Patient Details </Typography>}
             <Box className='subHeadingContainer'>
                   <Typography className="subHeading">We need a few details to schedule your session and verify your insurance coverage.</Typography>
             </Box>
           {this.state.nextForm? 
           <>
               <Formik
                    initialValues={this.state.formData}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                       
                            this.handleAPISubmit(values)
                    }} data-test-id="formik-first"
                    >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>

             <Box className='nameContainer'>
                <Box className='name'>
                    <Box className='firstnameContainer'>
                        <Typography className="mobileNumber">First Name <span className="mandotary">*</span></Typography>
                        <Field as={TextField} fullWidth variant="outlined" placeholder="Neelam"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        
                        />
                    </Box>
                    <Box className='firstnameContainer'>
                        <Typography className="mobileNumber">Last Name <span className="mandotary">*</span></Typography>
                        <Field as={TextField}  fullWidth variant="outlined" placeholder="Brar"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}/>
                    </Box>
                </Box>
                <Typography className="no-mobile">Your name should match your ID or Insurance Card</Typography>

             </Box>
             <Box className='nameContainer'>
                <Box className='name'>
                    <Box className='firstnameContainer'>
                        <Typography className="mobileNumber">Suffix <span className="mandotary">*</span></Typography>
                        <TextField fullWidth variant="outlined" placeholder="Mrs." select
                        value={this.state.selectedTitle || ""}
                        onChange={(e) => this.setState({ selectedTitle: e.target.value })}
                        defaultValue='Mrs.'
                        data-test-id="selecttitle"
                        >
                           
                              {['Mr.','Mrs.','Miss.'].map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                        </TextField>
                    </Box>
                    <Box className='firstnameContainer'>
                        <Typography className="mobileNumber">Date of Birth <span className="mandotary">*</span></Typography>
                        <Field as={TextField} fullWidth variant="outlined" placeholder="12 - 31 - 1992"
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    
                        error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                 
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <InsertInvitationIcon color="disabled"/>
                                </InputAdornment>
                            )
                        }}
                        />
                    </Box>
                </Box>
             </Box>
             <Box className='stateContainer'>
                 <Typography className="mobileNumber"> State <span className="mandotary">*</span></Typography>
                 <TextField fullWidth placeholder="Albama"select data-test-id="selectstate"
                 
                 value={this.state.selectedState}
                        onChange={(e) => this.setState({ selectedState: e.target.value })}
                        defaultValue='Albama'
                 InputProps={{
                    startAdornment:(
                        <InputAdornment position="start">
                            <SearchOutlinedIcon color="disabled"/>
                        </InputAdornment>
                    )
                 }}>
                        {this.state.listOfStates.map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                
                 </TextField>
             </Box>
    
            <Box className='continuebtnContainer'>
                  <Button className="continue-btn" type="submit" data-test-id="submit">Submit</Button>
                  <Box className='loginContainer'>
                    <Typography className="login" >Already have an account? Log in</Typography>
                  </Box>
            </Box>
      <Button className="back-btn" onClick={()=>this.setState({nextForm:false})} data-test-id="backButton">Back</Button>
      </Form>
      )}
    </Formik>  
             </>:
             <>

            <Formik data-test-id="formik"
                initialValues={{ email: "", mobile: "" }}
                validationSchema={ValidationSchema}
                onSubmit={(values)=>{
                    this.setState({nextForm:true})
                }}
            >
                {({ errors, touched }) => (
                <Form noValidate>
                    <Box className='mobileNumberContainer'>
                        <Typography className='mobileNumber'>Mobile Number <span className="mandotary">*</span></Typography>
                        <Field as={TextField} fullWidth variant="outlined" name="mobile" 
                        error={touched.mobile && !!errors.mobile} 
                        helperText={touched.mobile && errors.mobile} 
                        className="mobileNumberTextfield" 
                        placeholder="+1 (123) 456-7890"/>
                    <Box className='no-mobileContainer'>
                        <Checkbox       sx={{
                "& .MuiSvgIcon-root": {
                borderRadius: "8px", // Adjust border-radius
                },}}
        /> <Typography className="no-mobile">I don't have a mobile - add a landline</Typography>
                    </Box>
                    </Box>
                    <Box className='emailContainer'>
                    <Typography className='mobileNumber'>Email<span className="mandotary">*</span></Typography>
                    <Field as={TextField} fullWidth variant="outlined" className="mobileNumberTextfield" 
                    error={touched.mobile && !!errors.email} 
                    helperText={touched.mobile && errors.email} 
                    placeholder="neelam@totallife.com" name="email"/>
        
                    </Box>
                    <Box className='prefered-contacts'>
                    <Typography className='mobileNumber'>Preferred Contact Method<span className="mandotary">*</span>
                    <span className="non-mandotary">(select all that apply)</span></Typography>
                    <Box className='checkBox-container'>
                        <Box sx={{display:'flex',gap:'8px',alignItems:'center'}}>
                        <Checkbox   data-test-id="checkbox"    sx={{
                "& .MuiSvgIcon-root": {
                borderRadius: "8px", // Adjust border-radius
                },}} checked={this.state.selectedContactMethods.includes('phone')}
                onChange={() => this.handleCheckboxChange('phone')}
        /> <Typography className="no-mobile">Phone</Typography>
        
                        </Box>
                        <Box sx={{display:'flex',gap:'8px',alignItems:'center'}}>
                        <Checkbox   data-test-id="checkbox"    sx={{
                "& .MuiSvgIcon-root": {
                borderRadius: "8px", // Adjust border-radius
                },}} checked={this.state.selectedContactMethods.includes('email')}
                onChange={() => this.handleCheckboxChange('email')}
        /> <Typography className="no-mobile">Email</Typography>
        
                        </Box>
                        <Box sx={{display:'flex',gap:'8px',alignItems:'center'}}>
                        <Checkbox   data-test-id="checkbox"    sx={{
                "& .MuiSvgIcon-root": {
                borderRadius: "8px", // Adjust border-radius
                },}} checked={this.state.selectedContactMethods.includes('sms')}
                onChange={() => this.handleCheckboxChange('sms')}
        /> <Typography className="no-mobile">SMS</Typography>
        
                        </Box>
        
                    </Box>
            
                    </Box>
                    <Box className='agreement-box'>
                        <Checkbox/>
                        <Typography className="agreement-text">I agree with the 
                            <span className="underline-text"> Terms of Use </span>
                            and 
                            <span className="underline-text"> Privacy Policy.</span>
                        </Typography>
        
                    </Box>
                    <Box className='continuebtnContainer'>
                        <Button className="continue-btn" 
                        type="submit" data-test-id="continue">Continue</Button>
                        <Box className='loginContainer'>
                            <Typography className="login">Already have an account? Log in</Typography>
                        </Box>
                    </Box>
                    
                   <Button className="back-btn" >Back</Button>
                   </Form>
                    )}
                </Formik>
                          </>
             }
            
    </Box>
    <RegistrationFooter/>
        </Box>
        </StyledWrapper>
// Customizable Area End
)}}
// Customizable Area Start
const StyledWrapper=styled(Box)({
    "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed',  top: 0, left: 0, zIndex: 99999 },
    "& .TopHeader": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff",justifyContent:'space-between'},
    "& .LOGO": { marginLeft: "40px", width: "162px", height: "41.24px" },
    "& .mainBox":{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'#FFFAF6',justifyContent:'space-between'},
    "& .SignupTracker":{width:'600px',marginTop:'32px',},
"& .steplabel":{
fontFamily:'Poppins',
fontWeight:700,
fontSize:'16px'
},
"& .mainHeading":{
    fontFamily:'Raleway',
    fontSize:'41px',
    fontWeight:600,
    color:'#2C2524',
    marginTop:'50px'
},
            
"& .subHeadingContainer":{
    width:'600px',
    textAlign:'center',
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#2C2524',
    marginTop:"32px"
},
"& .mobileNumberContainer":{
width:'600px',
height:'120px',
marginTop:'32px'
},
"& .mobileNumber":{
    fontFamily:'Poppins',
    fontSize:'16px',
    fontWeight:700,
    color:'#2C2524'
},
"& .mandotary":{
    color:'#DC2626',fontWeight:700
},
"&  .MuiOutlinedInput-root": {
    height: '44px',
    borderRadius: '24px',
    borderColor: '#E6C79C',

},
"& .MuiOutlinedInput-notchedOutline ": {
    borderColor: '#E6C79C !important'
},
"& .no-mobileContainer":{
    display:'flex',
    gap:'5px',
    alignItems:'center'
},
"& .no-mobile":{
    fontFamily:'Poppins',
    fontSize:'16px',
    color:'#2C2524',

},
"& .emailContainer":{
    marginTop:'24px',
    width:'600px'
},
"& .non-mandotary": { fontSize: '16px', color: '#78716C', fontWeight: 400 },
"& .checkBox-container":{
    width:'290px',
    display:'flex',
    justifyContent:'space-between',
},
"& .prefered-contacts":{
    marginTop:'24px',
    width:'600px'
},
"& .agreement-box":{
    marginTop:'24px',
    display:'flex',
    gap:'8px',
    width:'615px',
    alignItems:'center'
},
"& .agreement-text":{
    fontFamily:'Poppins',
    fontSize:'20px',
    color:'#2C2524'
},
"& .underline-text":{
    fontStyle:'italic',
    color:'#1A4A42',
    textDecoration:'underline'
},
"& .continuebtnContainer":{
    width:'600px',
    height:'107px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:'32px',
},
"& .continue-btn":{
    width:'100%',
    height:'56px',
    backgroundColor:'#E46760',
    textTransform:'capitalize',
    borderRadius:'24px',
    fontSize:'20px',
    color:'#fff',
    fontWeight:700,
    "&:hover":{
        backgroundColor:'#E46760', 
    },

},

"& .loginContainer":{
    width:'600px',
    display:'flex',
    justifyContent:'center'
},
"& .login":{
    fontFamily:'Raleway',
    fontSize:'20px',
    fontWeight:700,
    color:'#E46760',
    textDecoration:'underline',
    
},
"& .footer":{
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    backgroundColor:'#fff',
    height:'100px',
    borderTop:'1px solid #E7E5E4'
},
"& .emergency-text-container":{
  width:'412px',
  display:'flex',
  justifyContent:'space-between',
},
"& .warningIcon":{
color:'#D97706',
width:'24px',
height:'24px'
},
"& .emergengy-text":{
    color:'#2C2524',
    fontFamily:'Poppins',
    fontSize:'20px',
},
"& .emergency-no":{
    color:'#D97706',
    fontFamily:'Poppins',
    fontSize:'20px',
    textDecoration:'underline'

},
"& .call-btn-container":{
    width:'356px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'

},
"& .help-text":{
    fontFamily:'Poppins',
    fontSize:'20px',
    fontWeight:700,
    color:'#2C2524'
},
"& .call-btn":{
    width:'229px',
    height:'44px',
    borderRadius:'24px',
    border:'2px solid #E46760',
    textTransform:'initial',
fontFamily:'Raleway',
fontSize:'20px',
fontWeight:700,
color:'#E46760',
"&:hover":{
    border:'2px solid #E46760', 
}
},
"& .back-btn":{
    position:'relative',
    width:'51px',
    fontFamily:'Raleway',
    fontSize:'20px',
    color:'#78716C',
    fontWeight:700,
    textTransform:'capitalize',
    right:'250px',
    bottom:'100px'

},
"& .nameContainer":{
    width:'600px',
    height:'100px',
    marginTop:'32px'
},
"& .name":{
    width:'600px',
    height:"72px",
    display:'flex',
    justifyContent:'space-between',

},
"& .firstnameContainer":{
    width:'288px',

},
"& .stateContainer":{
    marginTop:'24px',
    width:'600px',
    height:'72px'
}




})
// Customizable Area End